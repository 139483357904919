import React from 'react';

import './phone.css'
const Phone = () =>
{
    return (
        <div className='phone'>
            <div className='phone_content'>
                <img src='https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fphonetiktok%2Ftel-tiktok-full.svg?alt=media&token=94b05742-0246-4679-963c-8ebb1d1860cd' alt='tiktok' />
            </div>
        </div>
    )
}

export default Phone;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    currentCode: '',
    contents: [],
}


export const codeMachineSlice = createSlice({
  name: 'codeMachine',
  initialState,
  reducers: {
    openCodeMachine: (state, action) =>
    {
        state.isOpen = true
        state.contents = action.payload
    },
    closeCodeMachine: (state) => {
        state.isOpen = false
    },
    addCode: (state, action) => {
        state.currentCode = state.currentCode + action.payload
    },
      reset: (state) =>
      {
        const contents = {
            id: 1,
            text: "Oups il a une erreur de code",
            type: 'bad',
        }
        state.currentCode = ''
        state.contents = contents
      },
  },
})

export const { openCodeMachine, closeCodeMachine, addCode, reset } = codeMachineSlice.actions

export const selectCodeMachine = (state) => state.codeMachine


export default codeMachineSlice.reducer
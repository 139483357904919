import React from "react";

import './pickUp.css';

const PickUp = ({ capsule, card, id}) =>
{

    const handleContent = (type, items) =>
    {
        return (
            <div className={`pickUp-${type}`}>
                {items.map(itemImage => <img src={itemImage} alt={`${type}-${id}`}/>)}
            </div>
        )
    }
    return (
        <div className="pickUp">
            {capsule && handleContent('capsule', capsule)}
            {card && handleContent(card.length > 1 ? 'cards' : 'card', card)}
        </div>
    )
}

export default PickUp;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    openModalIntroduction,
    closeModalIntroduction,
    selectModalIntroduction,
    checkedModalIntroduction,
} from '../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
    selectModalTutorial,
} from '../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { initStep, selectSteps } from '../steps/StepsSlice';
import { selectCodeMachine } from '../reusableComponents/codeMachine/codeMachineSlice';
import { selectGames } from '../games/gamesSlice';

import Header from '../reusableComponents/header/Header';
import ModalIntroduction from '../reusableComponents/modal/modalIntroduction/ModalIntroduction';
import ModalTutorial from '../reusableComponents/modal/modalTutorial/ModalTutorial';
import Steps from '../steps/Steps';
import CodeMachine from '../reusableComponents/codeMachine/CodeMachine';
import Games from '../games/Games';

import './Introduction.css';

const Introduction = () => {
    const modalIntroduction = useSelector(selectModalIntroduction);
    const modalTutorial = useSelector(selectModalTutorial);
    const codeMachine = useSelector(selectCodeMachine);
    const games = useSelector(selectGames);

    const steps = useSelector(selectSteps);

    const dispatch = useDispatch();

    const nextModalTutorial = () => {
        dispatch(closeModalIntroduction());
        const handleLastTutorial = () => {
            dispatch(closeModalTutorial());
            const contentsModal = {
                contents: [
                    'J’ai passé tous les contrôles et tous les tests physiques avec succès. Mais je vous avoue qu’il y a une chose que j’ai cachée aux médecins… je fume. Et je ne peux pas me passer de cigarettes.',
                    'J’ai mon dernier point avec le médecin du travail d’ici quelques minutes.',
                ],
                type: 'left',
                buttonText: 'Suivant',
                onClick: () => {
                    dispatch(closeModalIntroduction());
                    dispatch(initStep());
                },
            };
            dispatch(openModalIntroduction(contentsModal));
        };

        const tutorialContents = [
            {
                id: 1,
                text: 'Pour que Camille puisse partir en mission, il va falloir réunir tous les morceaux de la fusée. Les morceaux de la fusée sont disséminées un peu partout. Pour pouvoir les récupérer, tu vas devoir résoudre des énigmes.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro1.png?alt=media&token=a0d9d52b-2c8e-4e62-ab0c-e84eaaf64cf4',
                ],
            },
            {
                id: 2,
                text: 'Pour ce faire tu disposes de plusieurs éléments. Commençons par les plus imposants: les supports de jeu. Ils sont au nombre de 3.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro2.png?alt=media&token=3b00e146-c776-48d3-8fd4-283669ba19a9',
                ],
            },
            {
                id: 3,
                text: 'Le premier correspond à la tour de lancement. Mais comme tu peux le voir, il n’y a pas de fusée pour le moment. En effet, c’est ici, que tu devras placer les morceaux récupérés. ',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ffuseee-support.png?alt=media&token=458b66d8-4aed-41db-a56d-b0b390ac4cb6',
                ],
            },
            {
                id: 4,
                text: 'Nous retrouvons ensuite le support pour placer les capsules. Les capsules sont des scènes qui te permettront de  situer l’endroit où se déroule l’histoire. Attention, certaines scènes sont déjà placées sur le support.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule-support.png?alt=media&token=b6ca1b63-544c-450d-988b-4ed6d4866840',
                ],
            },
            {
                id: 5,
                text: 'Et pour finir, il y a l’emplacement des cartes. Lorsque tu devras piocher ou te défausser d’une carte tout se passera à cet endroit.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcartes-support.png?alt=media&token=c9eacab7-66a4-4925-97c0-dd509edec6ac',
                ],
            },
            {
                id: 6,
                text: 'Pour une bonne disposition des supports regarde l’exemple à l’écran et essaye de le reproduire. ',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro6.png?alt=media&token=60c61edf-df3d-41d4-a910-1baaa6c180e0',
                ],
            },
            {
                id: 7,
                text: 'Mais revenons un peu plus en détails sur les cartes. Tout d’abord on retrouve les cartes bleues, appelées aussi cartes objets. Sur chacune d’elle, en haut à droite, tu retrouves un motif ou une forme.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro7.png?alt=media&token=a2181a34-7164-433a-99a4-9f1159bc00ec',
                ],
            },
            {
                id: 8,
                text: 'Elles peuvent se combiner pour te permettre de débloquer de nouvelles cartes. Associer le motif et la forme te donne un dessin complet. A toi de le retrouver dans la pioche.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro8.png?alt=media&token=68e05a4d-fc47-4192-b6da-74e65f706655',
                ],
            },
            {
                id: 9,
                text: 'Ensuite on a les cartes vertes, les cartes indices. Elles vont te permettre d’avancer dans l’intrigue ou d’obtenir des informations supplémentaires.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro9.png?alt=media&token=a920218e-9abd-4093-acc1-1e1ccc45b8ef',
                ],
            },
            {
                id: 10,
                text: 'On a aussi les cartes rouges. Ce sont des cartes erreurs. Elles t’indiquent que tu t’es trompé et qu’il va falloir essayer une nouvelle combinaison.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro10.png?alt=media&token=344ead97-2645-493c-8eb9-eecb863026bc',
                ],
            },
            {
                id: 11,
                text: "Pour finir, nous avons les cartes jaunes ou “machines” qui te permettent d'interagir avec l’ordinateur, c’est-à-dire moi. Entre le code situé en bas de la carte pour accéder à une nouvelle interface. ",
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro11.png?alt=media&token=e0e0de20-9beb-4b88-b02d-3106b1d3618f',
                ],
            },
            {
                id: 12,
                text: 'N’oublie pas, associer des cartes bleues entre elles peut permettre de débloquer de nouvelles cartes jaunes, rouges et/ou vertes.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fintro12.png?alt=media&token=05f0d2d2-0e09-447f-b524-ad14da4fa753',
                ],
            },
            {
                id: 13,
                text: 'Il semblerait que Camille vient de recevoir un message. Appuie dessus pour pouvoir interagir avec.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ftelephone.svg?alt=media&token=058a2f10-91a2-4a80-a0e1-f440ba98c3ff',
                ],
            },
            {
                id: 14,
                text: 'Cela a l’air important. Vite ! Retrouvons Camille pour la prévenir.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fsms%2Btexte.svg?alt=media&token=4219ecee-7b7d-467a-a79a-b8ac490802fa',
                ],
                onClick: () => handleLastTutorial(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
        dispatch(checkedModalIntroduction());
    };

    const nextModalIntro = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Bonjour ! Je suis Bibot.',
                "Je vois que tu viens de rencontrer Camille. Comme elle te l’a dit elle s'apprête à partir dans l’espace.",
                'Cependant elle va avoir besoin de ton aide.',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => nextModalTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    useEffect(() => {
        if (!modalIntroduction.isChecked) {
            const contentsModal = {
                contents: [
                    'Ah vous voilà enfin ! Avant tout, faisons un petit peu connaissance. Je suis Camille, astronaute… C’est mon rêve depuis toute petite !',

                    'Dans une heure, je dois embarquer pour ma première mission.',
                ],
                type: 'left',
                buttonText: 'Suivant',
                onClick: () => nextModalIntro(),
            };
            dispatch(openModalIntroduction(contentsModal));
        }
    }, []);
    return (
        <div className="introduction">
            <Header />
            <div className="dialogue">
                {modalIntroduction.isOpen && <ModalIntroduction />}
            </div>
            {modalTutorial.isOpen && <ModalTutorial />}
            {codeMachine.isOpen && <CodeMachine />}
            {steps.currentStep !== null && steps.isOpen && <Steps />}
            {games.isOpen && <Games />}
        </div>
    );
};

export default Introduction;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectSteps, closeStep } from './StepsSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { openCodeMachine } from '../reusableComponents/codeMachine/codeMachineSlice';

import Button from '../reusableComponents/button/Button';

import './steps.css';

const Steps = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const playAgain = () => {
        navigate('/');
        window.location.reload();
    };

    const [tutorialContents, setTutorialContents] = useState([]);

    const steps = useSelector(selectSteps);

    const handleOpenCode = () => {
        dispatch(closeModalTutorial());
        dispatch(closeStep());
        const contentModal = {
            id: 1,
            text: 'Prêts à jouer ? Je reste en veille, si tu trouves une carte jaune machine avec un code n’hésite pas à le rentrer dans mon interface pour voir ce qu’il se passe.',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        dispatch(closeStep());
        const contentModal = {
            id: 1,
            text: 'N’oublie pas en combinant 2 cartes bleues tu peux obtenir une carte jaune machine qui te permet d’avoir un nouveau code à me partager.',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleCodeMachineTwo = () => {
        dispatch(closeModalTutorial());
        dispatch(closeStep());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    useEffect(() => {
        switch (steps.currentStep) {
            case 1:
                setTutorialContents([
                    {
                        id: 1,
                        text: 'La première capsule est directement imprimée sur le support. Récupère juste les cartes qui lui sont associées.',
                        type: 'smile',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-1.png?alt=media&token=8de6d351-889f-4c61-a894-af01c816185d',
                        ],
                        card: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes1.png?alt=media&token=67e45540-c7c1-4671-b3e4-9f48b3ebe3b3',
                        ],
                    },
                    {
                        id: 2,
                        text: 'Il s’agit du cabinet médical du médecin de la N.A.S.A., là où Camille a justement rendez vous.',
                        type: 'oh',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-1.png?alt=media&token=8de6d351-889f-4c61-a894-af01c816185d',
                        ],
                        onClick: () => handleOpenCode(),
                    },
                ]);
                break;
            case 2:
                setTutorialContents([
                    {
                        id: 1,
                        text: 'Tu vas devoir revenir sur 3 moments clés de la vie de Camille qui l’ont incitée à commencer la cigarette. Pour ce faire tu vas pouvoir utiliser la machine à remonter le temps. Retourne le support Capsule.',
                        type: 'smile',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-1.png?alt=media&token=8de6d351-889f-4c61-a894-af01c816185d',
                        ],
                    },
                    {
                        id: 2,
                        text: 'Son premier souvenir est dans la capsule n°2. Mets la en jeu et pioche les cartes liées.',
                        type: 'oh',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-2.png?alt=media&token=f4cb6c18-6eef-4198-bcf8-d3a28897b425',
                        ],
                        card: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes3.png?alt=media&token=4f4732bf-ab21-48f9-b681-9af8af300bc9',
                        ],
                    },
                    {
                        id: 3,
                        text: "Il semblerait qu’on ait atterri dans la chambre de Camille quand elle était plus jeune. Elle n'a pas menti quand elle disait que sa passion pour l’espace remontait à il y a bien longtemps.",
                        type: 'smile',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-2.png?alt=media&token=f4cb6c18-6eef-4198-bcf8-d3a28897b425',
                        ],
                        onClick: () => handleCodeMachine(),
                    },
                ]);
                break;
            case 3:
                setTutorialContents([
                    {
                        id: 1,
                        text: 'Pour poursuivre le jeu, mets en jeu la capsule n° 3 et pioche les cartes liées.',
                        type: 'smile',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-3.png?alt=media&token=2bd486ea-7504-4830-b79c-9a080d54f04d',
                        ],
                        card: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcartes6.png?alt=media&token=6b6539d1-6c7c-4bcc-91f5-fadf6c613fde',
                        ],
                    },
                    {
                        id: 2,
                        text: 'La troisième capsule est directement imprimée sur le support. Récupère juste les cartes qui lui sont associées.',
                        type: 'oh',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-3.png?alt=media&token=2bd486ea-7504-4830-b79c-9a080d54f04d',
                        ],
                        onClick: () => handleCodeMachineTwo(),
                    },
                ]);
                break;
            case 4:
                setTutorialContents([
                    {
                        id: 1,
                        text: 'Pour poursuivre le jeu, mets en jeu la capsule n° 4 et pioche les cartes liées.',
                        type: 'smile',
                        content: 'pickUp',
                        capsule: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcapsule-4.png?alt=media&token=bc7c729f-b757-42e8-96e4-de647783f3e5',
                        ],
                        card: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Fcartes9.png?alt=media&token=1802f0a7-c460-4540-a463-4461316caa54',
                        ],
                    },
                    {
                        id: 2,
                        text: 'Cette fois ci on est à la sortie des cours, on dirait bien. Regarde tout ce monde.',
                        type: 'oh',
                        content: 'pickUp',
                        card: [
                            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes9.png?alt=media&token=f38d500f-4abb-4075-9eec-3c4e5400ba69',
                        ],
                        onClick: () => handleCodeMachineTwo(),
                    },
                ]);
                break;
            default:
                break;
        }
    }, []);

    if (steps.currentStep === 5) {
        return (
            <div className="steps">
                <div className="steps-title">
                    <h1>Merci d'avoir joué avec nous</h1>
                </div>
                <Button
                    title="Rejouer"
                    type="primary"
                    onClick={() => playAgain()}
                />
            </div>
        );
    }

    return (
        <div className="steps">
            <div className="steps-title">
                <h1>Nouvelle capsule !</h1>
                {steps.currentStep === 1 && (
                    <p>Il est temps de commencer la première partie du jeu</p>
                )}
            </div>
            <Button
                title="Suivant"
                type="primary"
                onClick={() => dispatch(openModalTutorial(tutorialContents))}
            />
        </div>
    );
};

export default Steps;

import React from 'react';
import { Link } from 'react-router-dom';

import './button.css'

const ButtonLink = ({ title, type, href }) =>

{
    if (!type) {
        type = 'second'
    }
    return (
        <Link className={`button button--${ type }`} to={href} >{title}</Link>
    )
}

export default ButtonLink;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import ButtonReturn from '../../reusableComponents/button/ButtonReturn';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';

import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { closeGame, message } from '../gamesSlice';

import './cigaret.css';

const Cigaret = () => {
    const dispatch = useDispatch();

    const [nicotine, setNicotine] = useState([
        {
            id: 1,
            isChecked: false,
        },
        {
            id: 2,
            isChecked: false,
        },
        {
            id: 3,
            isChecked: false,
        },
    ]);

    const [goudron, setGoudron] = useState([
        {
            id: 4,
            isChecked: false,
        },
        {
            id: 5,
            isChecked: false,
        },
        {
            id: 6,
            isChecked: false,
        },
    ]);

    const [mercure, setMercure] = useState([
        {
            id: 7,
            isChecked: false,
        },
        {
            id: 8,
            isChecked: false,
        },
        {
            id: 9,
            isChecked: false,
        },
    ]);

    const [arsenic, setArsenic] = useState([
        {
            id: 10,
            isChecked: false,
        },
        {
            id: 11,
            isChecked: false,
        },
        {
            id: 12,
            isChecked: false,
        },
    ]);

    const [ammoniac, setAmmoniac] = useState([
        {
            id: 13,
            isChecked: false,
        },
        {
            id: 14,
            isChecked: false,
        },
        {
            id: 15,
            isChecked: false,
        },
    ]);

    const [listElementChecked, setListElementChecked] = useState([]);

    const handleClick = (index, slug) => {
        let newArray;

        switch (slug) {
            case 'nicotine':
                newArray = [...nicotine];
                newArray[index].isChecked = !newArray[index].isChecked;
                setNicotine(newArray);

                break;
            case 'goudron':
                newArray = [...goudron];
                newArray[index].isChecked = !newArray[index].isChecked;
                setGoudron(newArray);

                break;
            case 'mercure':
                newArray = [...mercure];
                newArray[index].isChecked = !newArray[index].isChecked;
                setMercure(newArray);
                break;
            case 'arsenic':
                newArray = [...arsenic];
                newArray[index].isChecked = !newArray[index].isChecked;
                setArsenic(newArray);
                break;
            case 'ammoniac':
                newArray = [...ammoniac];
                newArray[index].isChecked = !newArray[index].isChecked;
                setAmmoniac(newArray);
                break;
            default:
                break;
        }

        let newArrayChecked = [...listElementChecked];
        const isElementInArraychecked = newArrayChecked.find(
            (element) => element === newArray[index].id
        );

        if (isElementInArraychecked === undefined) {
            newArrayChecked.push(newArray[index].id);
            setListElementChecked(newArrayChecked);
        } else {
            const indexToDelete = newArrayChecked.findIndex(
                (element) => element === newArray[index].id
            );
            newArrayChecked.splice(indexToDelete, 1);
            setListElementChecked(newArrayChecked);
        }
    };

    const handleCheck = (index, slug) => {
        switch (slug) {
            case 'nicotine':
                if (nicotine[index].isChecked) {
                    return 'valid';
                }
                break;
            case 'goudron':
                if (goudron[index].isChecked) {
                    return 'valid';
                }
                break;
            case 'mercure':
                if (mercure[index].isChecked) {
                    return 'valid';
                }
                break;
            case 'arsenic':
                if (arsenic[index].isChecked) {
                    return 'valid';
                }
                break;
            case 'ammoniac':
                if (ammoniac[index].isChecked) {
                    return 'valid';
                }
                break;
            default:
                break;
        }
    };

    const handleElement = (elementSlug) => {
        return (
            <div className={`cigaret-element ${elementSlug}`}>
                <button
                    type="button"
                    className={`cigaret-element-button plant ${handleCheck(
                        0,
                        elementSlug
                    )}`}
                    onClick={() => handleClick(0, elementSlug)}
                />
                <button
                    type="button"
                    className={`cigaret-element-button fire ${handleCheck(
                        1,
                        elementSlug
                    )}`}
                    onClick={() => handleClick(1, elementSlug)}
                />
                <button
                    type="button"
                    className={`cigaret-element-button fabric ${handleCheck(
                        2,
                        elementSlug
                    )}`}
                    onClick={() => handleClick(2, elementSlug)}
                />
            </div>
        );
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'N’oublie pas, quand tu trouves une carte jaune machine, de rentrer le code dans mon interface.',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 2,
                text: 'Pour poursuivre le jeu pioche la cartes indiquée.',
                type: 'smile',
                content: 'pickUp',
                card: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes2.png?alt=media&token=c1e878d6-ed9c-4bf3-b210-0ff337b1eefb',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleNext = () => {
        dispatch(closeGame('dictaphone'));
        const contentsModal = {
            contents: [
                'Le tabac est une plante qui contient des substances chimiques comme les pesticides, utilisés pour le cultiver. Les fabricants de cigarettes ajoutent au tabac de nombreux autres produits chimiques.',
                'Mais ce qui rend la cigarette un produit véritablement dangereux, c’est avant tout la combustion, c’est-à-dire le fait de la brûler. En effet, lorsque le tabac et les produits ajoutés par les fabricants brûlent, ils libèrent de nombreuses substances très toxiques pour le corps humain.',
                'Fumer des cigarettes peut provoquer ou aggraver certaines maladies. Parmi les maladies les plus connues causées par le tabac, il y a les cancers, comme celui du poumon ou de la vessie. ',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitElement = () => {
        const goodElementArray = [1, 5, 9, 10, 15];
        listElementChecked.sort((firstElement, secondElement) => {
            return firstElement - secondElement;
        });

        const goodElementString = goodElementArray.toString();
        const listElementCheckedString = listElementChecked.toString();

        if (goodElementString === listElementCheckedString) {
            const contents = {
                id: 1,
                text: 'Bravo ! Tu as trouvé la bonne provenance des composants.',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: "Tu as fait  une erreur mais ne t'inquiètes pas tu peux recommencer.",
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };
    return (
        <div className="cigaret">
            <div className="cigaret-content">
                <div className="cigaret-left">
                    {handleElement('nicotine')}
                    {handleElement('goudron')}
                </div>
                <img
                    className="cigaret-center"
                    alt="cigarette"
                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fcigarette.svg?alt=media&token=f51c3a2d-8762-44e1-b2e9-082790339032"
                />
                <div className="cigaret-right">
                    {handleElement('mercure')}
                    {handleElement('arsenic')}
                    {handleElement('ammoniac')}
                </div>
            </div>
            <div>
                <Button title="Valider" onClick={() => submitElement()} />
                <ButtonReturn />
            </div>
        </div>
    );
};

export default Cigaret;

import React from 'react';

import ButtonLink from '../reusableComponents/button/ButtonLink';


import './home.css'

const Home = () =>
{
    return (
        <div className="home">
            <img src='https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fbkg-logo.svg?alt=media&token=708bf09d-9eef-4b88-a51a-42ef23982497' alt='logo-tricky'/>
            <ButtonLink title="commencer" type="primary" href='/introduction' />
        </div>
    )
}

export default Home;
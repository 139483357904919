import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentStep: null,
    isOpen: false,
}


export const stepsSlice = createSlice({
  name: 'steps',
  initialState,
    reducers: {
        initStep: (state) =>
        {

            state.currentStep = 1
            state.isOpen = true   

        },
        nextStep: (state) =>
        {
            state.currentStep = state.currentStep + 1
            state.isOpen = true   
        },
        closeStep: (state) =>
        {
            state.isOpen = false   
        },
  },
})

export const { initStep, nextStep, closeStep } = stepsSlice.actions

export const selectSteps = (state) => state.steps


export default stepsSlice.reducer

import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";


import { selectModalTutorial} from './modalTutorialSlice';

import TutorialRobot from './tutorialRobot/TutorialRobot';
import Tutorial from './tutorial/Tutorial';

import "./modalTutorial.css";

const ModalTutorial = () =>
{
    const slider = useRef(null);
    const modalTutorial = useSelector(selectModalTutorial);

    const  settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    
    return (
        <div className='modalTutorial'>
            <Slider ref={slider} {...settings}>
                {modalTutorial.contents.map(contentModal => (
                    <div className='modalTutorial-content'>
                        <Tutorial contentModal={contentModal}/>
                        <TutorialRobot contentModal={contentModal} slider={slider}/>
                    </div>
                ))}
            </Slider>
        </div>
    )
}


export default ModalTutorial;
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectCodeMachine,
    addCode,
    reset,
    closeCodeMachine,
    openCodeMachine,
} from './codeMachineSlice';
import { closeGame, initGame, message } from '../../games/gamesSlice';

import TutorialRobot from '../modal/modalTutorial/tutorialRobot/TutorialRobot';

import './codeMachine.css';

const CodeMachine = () => {
    const codeMachine = useSelector(selectCodeMachine);
    const dispatch = useDispatch();

    const listButton = [
        {
            title: '1',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-1.svg?alt=media&token=fc49d77e-4d0c-4157-89ee-8bde60582795',
        },
        {
            title: '2',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-2.svg?alt=media&token=9ab44f36-88e8-4673-85a6-fe023ffda42e',
        },
        {
            title: '3',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-3.svg?alt=media&token=59b79517-1c7c-4155-b5f4-d3523df11af9',
        },
        {
            title: '4',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-4.svg?alt=media&token=9c62b7c1-379f-45b7-8120-2f56f4f5ca7a',
        },
        {
            title: '5',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-5.svg?alt=media&token=69b172e1-1ecc-458e-abbc-8afa13917108',
        },
        {
            title: '6',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-6.svg?alt=media&token=99d9b707-3c28-48f5-8421-c241d3cf15dc',
        },
        {
            title: '7',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-7.svg?alt=media&token=6217a4e8-8006-4085-be9e-3358761addb5',
        },
        {
            title: '8',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-8.svg?alt=media&token=f42bcc88-f674-406d-a9c2-ba9a3ca6c050',
        },
        {
            title: '9',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-9.svg?alt=media&token=ddfbb903-5781-4679-bf81-8e675895a17f',
        },
        {
            title: 'abort',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-annuler.svg?alt=media&token=c308897e-4cf3-458a-96f9-0a8e7015cbb3',
        },
        {
            title: '0',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-0.svg?alt=media&token=abd9309a-31e1-4cd8-993d-1f871779f0ce',
        },
        {
            title: 'valid',
            image: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-valider.svg?alt=media&token=3136770f-76da-43e3-bfa2-be1e6af2aac1',
        },
    ];

    const handleCodeMachine = () => {
        dispatch(closeGame('tiroir'));

        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleCode = (content) => {
        if (content.title === 'valid') {
            let contentModal;
            switch (codeMachine.currentCode) {
                case '1129':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Retrouve la provenance des différents composants de cette cigarette. Clique sur un icone pour le sélectionnner.',
                            type: 'smile',
                        },
                        gameSlug: 'cigaret',
                    };

                    break;
                case '3094':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'On dirait que Camille s’est enregistrée sur ce dictaphone. Appuie sur le bouton lecture pour en découvrir plus.',
                            type: 'smile',
                        },
                        gameSlug: 'dictaphone',
                    };

                    break;
                case '3423':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: '( Question 1 ) Remplis le formulaire pour Camille.',
                            type: 'smile',
                        },
                        gameSlug: 'questions',
                    };

                    break;

                case '9184':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Voilà le compte TikTok de Camille… C’est ce jour-là que tout a basculé.',
                            type: 'oh',
                            onClick: () => handleCodeMachine(),
                        },
                        gameSlug: 'phone',
                    };

                    break;
                case '5621':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Mince ! Le tiroir est verrouillé par un code à 4 chiffres.  Qu’est ce que cela pourrait être ? Une date importante ? Un numéro de téléphone ? ...',
                            type: 'smile',
                        },
                        gameSlug: 'tiroir',
                    };

                    break;
                case '7610':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Ce casse-tête est tout mélangé ! Clique sur les différentes pièces pour les faire tourner et reformer les mots.',
                            type: 'smile',
                        },
                        gameSlug: 'puzzle',
                    };

                    break;
                case '9467':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Oulala ces phrases sont vraiment très négatives. Essaye de changer tout ça pour que Camille reprenne confiance en elle.',
                            type: 'smile',
                        },
                        gameSlug: 'book',
                    };

                    break;
                case '7550':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Je me demande ce que Camille aurait pu mettre comme mot de passe. Sûrement un dessin lié à l’Espace. Appuie sur les ronds dans le bon ordre pour les relier.',
                            type: 'smile',
                        },
                        gameSlug: 'android',
                    };

                    break;
                case '2174':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Ces couleurs, il me semble les avoir déjà vues quelques part... Clique sur une case pour inscrire une lettre à l’aide du clavier.',
                            type: 'smile',
                        },
                        gameSlug: 'color',
                    };

                    break;
                case '6591':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Camille est triste à la lecture du texte dans le miroir',
                            type: 'bad',
                        },
                        gameSlug: 'mirror',
                    };

                    break;
                case '8735':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Cette horloge semble ne pas indiquer la bonne heure. Saurais-tu retrouver quand la capsule se déroule ?',
                            type: 'smile',
                        },
                        gameSlug: 'clock',
                    };

                    break;
                case '9062':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Fait bien attention à l’illustration de la capsule. Il semblerait qu’il faut compter le nombre de personnes qui partent pour une activité sportive ou artistique, et combien s’arrêtent pour fumer.',
                            type: 'smile',
                        },
                        gameSlug: 'tabac',
                    };

                    break;
                case '4821':
                    contentModal = {
                        contents: {
                            id: 1,
                            text: 'Il me semble que j’ai déjà vu ce type d’énigme. Il suffit de relier les points entre eux. Regarde dans tes indices peut-être tu trouveras de l’aide.',
                            type: 'smile',
                        },
                        gameSlug: 'constellation',
                    };

                    break;
                default:
                    const contents = {
                        id: 1,
                        text: 'Oups il a une erreur de code',
                        type: 'bad',
                    };
                    dispatch(message(contents));
                    dispatch(reset());
            }

            dispatch(initGame(contentModal));
            dispatch(closeCodeMachine());
            dispatch(reset());
        } else if (content.title === 'abort') {
            dispatch(reset());
        } else {
            dispatch(addCode(content.title));
        }
    };

    return (
        <div className="codeMachine">
            <div className="codeMachine-digit">
                <p>{codeMachine.currentCode}</p>
                <div className="codeMachine-digit-number">
                    {listButton.map((buttonContent) => (
                        <button
                            type="button"
                            onClick={() => handleCode(buttonContent)}
                        >
                            <img
                                src={buttonContent.image}
                                alt={buttonContent.title}
                            />
                        </button>
                    ))}
                </div>
            </div>
            <TutorialRobot contentModal={codeMachine.contents} />
        </div>
    );
};

export default CodeMachine;

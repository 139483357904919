import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { closeGame, message } from '../gamesSlice';

import './puzzle.css';

const Puzzle = () => {
    const dispatch = useDispatch();

    const [firstLine, setFirstLine] = useState([
        {
            id: 1,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-1.svg?alt=media&token=6429b489-d238-4e9e-b4b5-59ee26568765',
            rotate: 180,
        },
        {
            id: 2,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-2.svg?alt=media&token=0c12d5b3-4fff-448f-9b2c-711d61165a36',
            rotate: 90,
        },
        {
            id: 3,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-3.svg?alt=media&token=befab2fa-d39e-4bb2-befd-51fd80aa7b03',
            rotate: 90,
        },
        {
            id: 4,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-4.svg?alt=media&token=169488d7-d718-4ead-80fd-f8ba0ab6040c',
            rotate: 90,
        },
        {
            id: 5,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-5.svg?alt=media&token=451c231e-3a64-4a6c-819b-b9937413500a',
            rotate: 180,
        },
    ]);
    const [secondLine, setSecondLine] = useState([
        {
            id: 6,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-6.svg?alt=media&token=bbc224d4-a739-46cd-839a-0ec0a1fe50a2',
            rotate: 180,
        },
        {
            id: 7,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-7.svg?alt=media&token=f303562f-7751-47e2-9cf0-96b96459a9ba',
            rotate: 270,
        },
        {
            id: 8,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-8.svg?alt=media&token=ce5bec6c-1702-47af-89d2-4e7a8e62e6d9',
            rotate: 90,
        },
        {
            id: 9,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-9.svg?alt=media&token=faedee1e-493a-44be-97a8-fbc489236173',
            rotate: 90,
        },
        {
            id: 10,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-10.svg?alt=media&token=e1ebb222-7229-492d-b9ba-3824e103e85f',
            rotate: 180,
        },
    ]);
    const [thirdLine, setThirdLine] = useState([
        {
            id: 11,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-11.svg?alt=media&token=b157fa80-a566-4453-84d1-614f09e67472',
            rotate: 180,
        },
        {
            id: 12,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-12.svg?alt=media&token=0dc59742-e946-4ce0-8be6-860aaa28e7b0',
            rotate: 270,
        },
        {
            id: 13,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-13.svg?alt=media&token=9d33ee60-5947-4185-ad9c-1678309721b9',
            rotate: 270,
        },
        {
            id: 14,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-14.svg?alt=media&token=65857244-6827-4261-9e2f-804fa7fd3504',
            rotate: 180,
        },
        {
            id: 15,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-15.svg?alt=media&token=efd8f487-1c21-42a8-8088-608a05f1892d',
            rotate: 90,
        },
    ]);
    const [fourthLine, setFourthLine] = useState([
        {
            id: 16,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-16.svg?alt=media&token=029abd68-1c00-4b6f-adf7-bfb4e432a987',
            rotate: 180,
        },
        {
            id: 17,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-17.svg?alt=media&token=27791771-51b0-47b8-9f06-b1dd1a673546',
            rotate: 180,
        },
        {
            id: 18,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-18.svg?alt=media&token=cf24fba3-2bf0-465e-bec6-6d18eae5401e',
            rotate: 270,
        },
        {
            id: 19,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-19.svg?alt=media&token=8ad32658-3a3b-44d0-97ef-1fa7ca323496',
            rotate: 360,
        },
        {
            id: 20,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-20.svg?alt=media&token=88bea59a-9f6f-4a2f-91b1-1158424ce865',
            rotate: 180,
        },
    ]);

    const handleRotate = (content, index, type) => {
        switch (type) {
            case 'firstLine':
                let newFirstLine = [...firstLine];
                if (newFirstLine[index].rotate === 360) {
                    newFirstLine[index].rotate = 90;
                } else {
                    newFirstLine[index].rotate =
                        newFirstLine[index].rotate + 90;
                }
                setFirstLine(newFirstLine);
                break;
            case 'secondLine':
                let newSecondLine = [...secondLine];
                if (newSecondLine[index].rotate === 360) {
                    newSecondLine[index].rotate = 90;
                } else {
                    newSecondLine[index].rotate =
                        newSecondLine[index].rotate + 90;
                }
                setSecondLine(newSecondLine);
                break;
            case 'thirdLine':
                let newThirdLine = [...thirdLine];
                if (newThirdLine[index].rotate === 360) {
                    newThirdLine[index].rotate = 90;
                } else {
                    newThirdLine[index].rotate =
                        newThirdLine[index].rotate + 90;
                }
                setThirdLine(newThirdLine);
                break;
            case 'fourthLine':
                let newFourthLine = [...fourthLine];
                if (newFourthLine[index].rotate === 360) {
                    newFourthLine[index].rotate = 90;
                } else {
                    newFourthLine[index].rotate =
                        newFourthLine[index].rotate + 90;
                }
                setFourthLine(newFourthLine);
                break;
            default:
                break;
        }
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code... ',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleTuto = () => {
        dispatch(closeModalIntroduction());
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 2,
                text: 'Pour poursuivre le jeu pioche les cartes indiquées.',
                type: 'smile',
                content: 'pickUp',
                card: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes5.png?alt=media&token=9e0f722b-c94b-46ff-bf13-0ec770fd4b73',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleNextCamilleTwo = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                "J’aurais peut-être pu en parler voire me confier sur ce que je ressentais, en y repensant cela m'aurait fait beaucoup de bien.",
                'Cela m’aurait aidé à trouver des solutions pour m’aérer l’esprit et me sentir mieux, comme faire du sport, de la relaxation, de la peinture, de la musique…',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTuto(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleNextCamille = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Je pensais tout le temps qu’il y avait des problèmes que je ne pouvais pas résoudre, cela me donnait mal au ventre.',
                'Je m’inquiétais beaucoup, même pour des petites choses.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleNextCamilleTwo(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleNext = () => {
        dispatch(closeGame('book'));
        const contentsModal = {
            contents: [
                'C’est vrai qu’en y repensant, j’ai toujours été stressée. ',
                'Je doutais en permanence de moi, donc à l’école, j’essayais de copier un peu les copains pour pouvoir m’intégrer et faire partie de leur groupe.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleNextCamille(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitPuzzle = () => {
        const checkFirstLine = firstLine.find(
            (puzzle) => puzzle.rotate !== 360
        );
        const checkSecondLine = secondLine.find(
            (puzzle) => puzzle.rotate !== 360
        );
        const checkThirdLine = thirdLine.find(
            (puzzle) => puzzle.rotate !== 360
        );
        const checkFourthLine = fourthLine.find(
            (puzzle) => puzzle.rotate !== 360
        );

        const goodFirstLine = checkFirstLine === undefined;
        const goodSecondLine = checkSecondLine === undefined;
        const goodThirdLine = checkThirdLine === undefined;
        const goodFourthLine = checkFourthLine === undefined;

        const puzzlIsGood =
            goodFirstLine && goodSecondLine && goodThirdLine && goodFourthLine;

        if (puzzlIsGood) {
            const contents = {
                id: 1,
                text: 'Bravo ! Tu as réussi le casse-tête avec succès !',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Oups !  Tu as dû rater une ou plusieurs cases. Mais heureusement, tu peux recommencer ! ',
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };

    return (
        <div className="puzzle">
            <div className="puzzle-element">
                <div className="puzzle-content">
                    {firstLine.map((puzzleContent, index) => (
                        <button
                            className="puzzle-content-button"
                            type="button"
                            onClick={() =>
                                puzzleContent.rotate !== 360 &&
                                handleRotate(puzzleContent, index, 'firstLine')
                            }
                        >
                            <img
                                style={{
                                    transform: `rotate(${puzzleContent.rotate}deg)`,
                                }}
                                src={puzzleContent.url}
                                draggable={false}
                                alt={`puzzle-${puzzleContent.id}`}
                            />
                        </button>
                    ))}
                </div>
                <div>
                    {secondLine.map((puzzleContent, index) => (
                        <button
                            className="puzzle-content-button"
                            type="button"
                            onClick={() =>
                                puzzleContent.rotate !== 360 &&
                                handleRotate(puzzleContent, index, 'secondLine')
                            }
                        >
                            <img
                                style={{
                                    transform: `rotate(${puzzleContent.rotate}deg)`,
                                }}
                                src={puzzleContent.url}
                                draggable={false}
                                alt={`puzzle-${puzzleContent.id}`}
                            />
                        </button>
                    ))}
                </div>
                <div>
                    {thirdLine.map((puzzleContent, index) => (
                        <button
                            className="puzzle-content-button"
                            type="button"
                            onClick={() =>
                                puzzleContent.rotate !== 360 &&
                                handleRotate(puzzleContent, index, 'thirdLine')
                            }
                        >
                            <img
                                style={{
                                    transform: `rotate(${puzzleContent.rotate}deg)`,
                                }}
                                src={puzzleContent.url}
                                draggable={false}
                                alt={`puzzle-${puzzleContent.id}`}
                            />
                        </button>
                    ))}
                </div>
                <div>
                    {fourthLine.map((puzzleContent, index) => (
                        <button
                            className="puzzle-content-button"
                            type="button"
                            onClick={() =>
                                puzzleContent.rotate !== 360 &&
                                handleRotate(puzzleContent, index, 'fourthLine')
                            }
                        >
                            <img
                                style={{
                                    transform: `rotate(${puzzleContent.rotate}deg)`,
                                }}
                                src={puzzleContent.url}
                                alt={`puzzle-${puzzleContent.id}`}
                            />
                        </button>
                    ))}
                </div>
            </div>
            <Button title="Valider" onClick={() => submitPuzzle()} />
        </div>
    );
};

export default Puzzle;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    currentGame: null,
    contents: [],
}


export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    initGame: (state, action) =>
    {
        state.isOpen = true
        state.contents = action.payload.contents
        state.currentGame = action.payload.gameSlug  
    },
    message: (state, action) =>
    {
        state.contents = action.payload
      },
    closeGame: (state, action) =>
    {
        state.isOpen = false
        state.contents = []
        state.currentGame = action.payload  
    },
  },
})

export const { initGame, message, closeGame } = gamesSlice.actions

export const selectGames = (state) => state.games


export default gamesSlice.reducer
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { closeGame, message } from '../gamesSlice';

import './clock.css';

const Clock = () => {
    const dispatch = useDispatch();

    const [hoursDeg, setHoursDeg] = useState(0);
    const [minutesDeg, setMinutes] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);

    const handleMinutes = (type) => {
        if (type === 'add') {
            setMinutes(minutesDeg + 180);
            setMinute(30);
            if (minutesDeg === 180) {
                if (hour === 12) {
                    setHour(1);
                } else {
                    setHour(hour + 1);
                }
                setMinute(0);
                setMinutes(0);
                setHoursDeg(hoursDeg + 30);
            }
        } else {
            if (minutesDeg === 0) {
                setMinutes(minutesDeg + 180);
                setMinute(30);
                if (hour === 0) {
                    setHour(11);
                    setHoursDeg(330);
                } else {
                    setHour(hour - 1);
                    setHoursDeg(hoursDeg - 30);
                }
            } else {
                setMinutes(minutesDeg - 180);
                setMinute(0);
            }
        }
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 1,
                text: 'Pour poursuivre le jeu pioche les cartes indiquées.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Fcartes10.png?alt=media&token=29004c9d-49d2-4824-b15c-8d98a22c02c1',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleIntroduction = () => {
        dispatch(closeGame('tabac'));
        const contentsModal = {
            contents: [
                'Ah ce doux bruit qui annonce la fin des cours! Je me souviens que devant le portail, beaucoup de mes camarades fumaient. ',
                'C’est d’ailleurs à ce moment-là qu’on m’a proposé ma première cigarette...',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitClock = () => {
        if (hour === 5 && minute === 0) {
            const contents = {
                id: 1,
                text: 'Bravo ! Encore une énigme résolue !',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleIntroduction(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Il semblerait que ça ne soit pas la bonne heure. Tu peux avoir un nouvel essai.',
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };

    return (
        <div className="clockContainer">
            <div>
                <Button
                    title="+ 30 min"
                    onClick={() => handleMinutes('add')}
                    type="third"
                />
                <Button
                    title="- 30 min"
                    onClick={() => handleMinutes('delete')}
                    type="third"
                />
            </div>
            <div className="clock_content">
                <article className="clock">
                    <div
                        className="hours-container"
                        style={{ transform: `rotate(${hoursDeg}deg)` }}
                    >
                        <div className="hours"></div>
                    </div>
                    <div
                        className="minutes-container"
                        style={{ transform: `rotate(${minutesDeg}deg)` }}
                    >
                        <div className="minutes"></div>
                    </div>
                    <div className="seconds-container">
                        <div className="seconds"></div>
                    </div>
                </article>
            </div>
            <Button title="Valider" onClick={() => submitClock()} />
        </div>
    );
};

export default Clock;

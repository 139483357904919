import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { closeGame, message } from '../gamesSlice';

import './tabac.css';

const Tabac = () => {
    const dispatch = useDispatch();

    const [numberPerson, setNumberPerson] = useState([
        {
            id: 1,
            title: 'TABAC',
            count: 0,
        },
        {
            id: 2,
            title: 'AUTRES ACTIVITES',
            count: 0,
        },
    ]);

    const handleCount = (index, type) => {
        let newNumberPerson = [...numberPerson];

        if (type === 'add') {
            newNumberPerson[index].count += 1;
        } else {
            newNumberPerson[index].count -= 1;
        }

        setNumberPerson(newNumberPerson);
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, répond aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 2,
                text: 'Pour poursuivre le jeu, pioche les cartes indiquées.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Fcartes11.png?alt=media&token=3dda2967-a08d-4a17-974a-005826aed28a',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleIntroductionTwo = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                "Mais… Qu'est-ce qui m'empêchait de rejoindre le club d’astronomie ? Ça aurait été mieux  que de vouloir m’intégrer au groupe de fumeurs…",
                'Ma confiance en moi ?',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroduction = () => {
        dispatch(closeGame('constellation'));
        const contentsModal = {
            contents: [
                'Finalement, les fumeurs n’étaient pas si nombreux que ça!',
                'C’est l’effet de groupe qui nous trompe toujours...',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionTwo(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitTabac = () => {
        const isGoodPersonTabac = numberPerson[0].count === 7;
        const isGoodPersonOther = numberPerson[1].count === 8;

        if (isGoodPersonTabac && isGoodPersonOther) {
            const contents = {
                id: 1,
                text: 'Rien ne te résiste ! A cette vitesse-là, Camille va pouvoir décoller très bientôt.',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleIntroduction(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Dommage, fais bien attention aux détails. Réessaie je suis sûr que tu vas trouver.',
                type: 'bad',
            };
            dispatch(message(contents));
        }

        let newNumberPerson = [...numberPerson];

        if (isGoodPersonTabac) {
            newNumberPerson[0].color = 'green';
        } else {
            newNumberPerson[0].color = 'red';
        }

        if (isGoodPersonOther) {
            newNumberPerson[1].color = 'green';
        } else {
            newNumberPerson[1].color = 'red';
        }

        setNumberPerson(newNumberPerson);
    };

    return (
        <div className="tabac">
            <div className="tabac_container">
                {numberPerson.map((person, index) => (
                    <div className="tabac_container-person">
                        <h3>{person.title}</h3>
                        <div className="tabac_container-person-buttons">
                            <button
                                type="button"
                                className="tabac_container-person-button"
                                onClick={() =>
                                    person.count !== 0 &&
                                    handleCount(index, 'remove')
                                }
                            >
                                <img
                                    className="tabac_container-person-button-negatif"
                                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Fbtn-.svg?alt=media&token=9db901ac-8d9e-47e9-974a-1f2f362dbbee"
                                    alt={`bouton ${person.title}`}
                                />
                            </button>
                            <div className="tabac_container-person-number">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Factivite-chiffre.svg?alt=media&token=a42e2609-cbb5-47ed-ab30-1826220212e0"
                                    alt={`personne ${person.title}`}
                                />
                                <h3 style={{ color: person.color }}>
                                    {person.count}
                                </h3>
                            </div>
                            <button
                                type="button"
                                className="tabac_container-person-button"
                                onClick={() => handleCount(index, 'add')}
                            >
                                <img
                                    className="tabac_container-person-button-positif"
                                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Fbtn%2B.svg?alt=media&token=55cfcb1d-565b-4da5-ac21-f8576c430d1d"
                                    alt={`bouton ${person.title}`}
                                />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <Button
                title="Valider"
                alt="valider tabac"
                onClick={() => submitTabac()}
            />
        </div>
    );
};

export default Tabac;

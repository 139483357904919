import React from 'react';
import { useSelector } from 'react-redux'

import
{
    selectModalIntroduction
} from './modalIntroductionSlice';
import Button from '../../button/Button';

import "./modalIntroduction.css";

const ModalIntroduction = () =>
{
    const modalIntroduction = useSelector(selectModalIntroduction)
    return (
        <div className={`modalIntroduction modalIntroduction--${ modalIntroduction.type }`}>
            <img
                className='modalIntroduction-bubble'
                alt='modal bubble'
                src={`${ modalIntroduction.type === 'left' ? "https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbubble%2Fbulle-camille.svg?alt=media&token=95953d63-df88-4118-9b75-a06f2b838143" : "https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbubble%2Fbulle-robot-intro.svg?alt=media&token=a2c89ab7-7109-4c72-a436-200847c7720a"}`}
            />
            <div className={`modalIntroduction-description modalIntroduction-description--${ modalIntroduction.type }`}>
                <div className='modalIntroduction-description-content'>
                    {modalIntroduction.contents.map(content => <p>{content}</p>)}
                    <Button title={modalIntroduction.buttonText} onClick={() => modalIntroduction.onClick()} />

                </div>
            </div>
            <img className={`modalIntroduction-avatar ${modalIntroduction.type}`} src={modalIntroduction.type === 'left' ? "https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Fcamille-normal.svg?alt=media&token=86039b83-4f24-46da-936f-e48611168210" : "https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-intro1.svg?alt=media&token=f90d860e-5e87-448b-8162-f05004d486da"} alt={modalIntroduction.type === 'left' ? "Camille" : "Robot"}/>
        </div>
    )
}

export default ModalIntroduction;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import { increment } from '../../reusableComponents/header/score/scoreSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { nextStep } from '../../steps/StepsSlice';
import { closeGame, message } from '../gamesSlice';

import './book.css';

const Book = () => {
    const dispatch = useDispatch();
    const [responses, setResponses] = useState([0, 2, 4]);

    const handleResponse = (value, index) => {
        const newResponse = [...responses];
        newResponse[index] = value;
        setResponses(newResponse);
    };

    const finishSecondStep = () => {
        dispatch(closeModalTutorial());
        dispatch(nextStep());
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        dispatch(increment());

        const tutorialContents = [
            {
                id: 1,
                text: 'Bravo, tu as récupéré ton deuxième bout de fusée! Tu te rapproches un peu plus du départ. Récupère la pièce de fusée correspondante et place-là sur le support.',
                type: 'win',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ffusee-2.png?alt=media&token=6ec2c3e2-096a-4bb7-b599-83c7fa17ceb7',
                ],
            },
            {
                id: 2,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
                onClick: () => finishSecondStep(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleNext = () => {
        dispatch(closeGame('android'));
        const contentsModal = {
            contents: [
                'L’anxiété est ressentie face à une situation dangereuse, menaçante, inévitable ou encore incontrôlable. Lorsque l’on est anxieux, le rythme de notre cœur s’accélère et nous nous mettons à transpirer.Cela peut aussi causer des problèmes de sommeil.',
                'Lorsque ces événements négatifs se reproduisent souvent, ils vont avoir un impact sur notre vie. Par exemple, Camille ne souhaitait plus aller à l’école par peur que l’on se moque d’elle.',
                'Il est important de parler de ce que l’on ressent, que ce soit positif ou négatif.Dans le cas de Camille, parler de ses crises d’anxiété lui aurait certainement permis de trouver des moyens de les calmer : exercices de respiration, de relaxation, des activités extra-scolaires sportives ou artistiques…',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitResponse = () => {
        const goodResponse = [1, 3, 5];
        const goodResponseString = goodResponse.toString();
        const responsesString = responses.sort(
            (firstElement, secondElement) => {
                return firstElement - secondElement;
            }
        );
        const isGoodResponse =
            responsesString.toString() === goodResponseString;

        if (isGoodResponse) {
            const contents = {
                id: 1,
                text: 'Bravo ! Grâce à toi Camille va pouvoir reprendre confiance en elle..',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Attention tu as laissé un ou plusieurs mots négatifs. Tu peux réessayer.',
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };
    return (
        <div className="book">
            <div className="book_content">
                <div className="book_content-left">
                    <p className="book_content-left-text">
                        Je suis
                        <select
                            name="noteOne"
                            className="book_content-left-text-select"
                            onChange={(e) => handleResponse(e.target.value, 0)}
                        >
                            <option value="0"> incapable </option>
                            <option value="1"> capable </option>
                        </select>
                        de faire quelque de bien
                    </p>
                    <p className="book_content-left-text">
                        Cette vidéo sur les planètes était vraiment
                        <select
                            name="noteTwo"
                            className="book_content-left-text-select"
                            onChange={(e) => handleResponse(e.target.value, 1)}
                        >
                            <option value="2">nulle</option>
                            <option value="3">une bonne idée</option>
                        </select>
                    </p>
                    <p className="book_content-left-text">
                        Tout ce que je fais est vraiment
                        <select
                            name="noteThre"
                            className="book_content-left-text-select"
                            onChange={(e) => handleResponse(e.target.value, 2)}
                        >
                            <option value="4">inutile</option>
                            <option value="5">utile</option>
                        </select>
                    </p>
                </div>
            </div>
            <Button title="Valider" onClick={() => submitResponse()} />
        </div>
    );
};

export default Book;

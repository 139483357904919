import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Timer from './timer/Timer';
import Score from './score/Score';

import './header.css';

const Header = () =>
{

    const navigate = useNavigate();

    const [isMusic, setIsMusic] = useState(true)
    const [isPlay, setIsPlay] = useState(false)

    return (
        <div className='header'>
            <Score />
            <Timer timerPause={isPlay}/>
            <div className='header-right'>
                <button type="button" onClick={() => setIsMusic(!isMusic)} className={`header-right-button header-right-button${isMusic ? '--music' : '--no-music'}`}></button>
                <button type="button" onClick={() => setIsPlay(!isPlay)} className={`header-right-button header-right-button${!isPlay ? '--play' : '--no-play'}`}></button>
                <button
                    type="button"
                    className='header-right-button header-right-button-closed'
                    onClick={() =>
                    { 
                        navigate('/');
                        window.location.reload();
                    }}>
                </button>
            </div>
        </div>
    )
}

export default Header;
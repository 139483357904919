import React from "react";

import BigTv from "./contents/tv/bigTv/BigTv";
import PickUp from "./contents/pickUp/PickUp";

const Tutorial = ({ contentModal }) =>
{

    switch (contentModal.content) {
        case 'tv':
            return <BigTv {...contentModal} />
        
        case 'pickUp':
            return <PickUp {...contentModal} />
        
        default:
            break;
    }
}

export default Tutorial;
import React from 'react';

import './button.css'

const Button = ({ title, type, onClick }) =>
{
    if (type === undefined || !type) {
        type = 'second'
    }
    return (
        <button type='button' className={`button button--${ type } `} onClick={() => onClick()}>{title}</button>
    )
}

export default Button;
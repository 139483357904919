import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import { closeGame, message } from '../gamesSlice';
import { nextStep } from '../../steps/StepsSlice';

import Button from '../../reusableComponents/button/Button';

import './questions.css';
import { increment } from '../../reusableComponents/header/score/scoreSlice';

const Questions = () => {
    const dispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(1);
    const [questions, setQuestions] = useState([
        {
            id: 1,
            text: 'Le matin, combien de temps après votre réveil fumez-vous votre première cigarette ?',
            goodAnswer: 0,
            isChecked: false,
            answerId: null,
            responses: [
                {
                    id: 1,
                    text: 'Dans les 5 premières minutes',
                    isChecked: false,
                },
                {
                    id: 2,
                    text: 'Entre 6 et 30 minutes',
                    isChecked: false,
                },
                {
                    id: 3,
                    text: 'Entre 30 et 60 minutes',
                    isChecked: false,
                },
                {
                    id: 4,
                    text: 'Après 60 minutes',
                    isChecked: false,
                },
            ],
        },
        {
            id: 2,
            text: 'Trouvez-vous qu’il est difficile de vous abstenir de fumer dans les endroits où c’est interdit ?',
            goodAnswer: 0,
            isChecked: false,
            answerId: null,
            responses: [
                {
                    id: 1,
                    text: 'Oui',
                    isChecked: false,
                },
                {
                    id: 2,
                    text: 'Non',
                    isChecked: false,
                },
            ],
        },
        {
            id: 3,
            text: 'À quelle cigarette de la journée renonceriez-vous le plus difficilement ?',
            goodAnswer: 0,
            isChecked: false,
            answerId: null,
            responses: [
                {
                    id: 1,
                    text: 'La première du matin',
                    isChecked: false,
                },
                {
                    id: 2,
                    text: "N'importe quelle autre",
                    isChecked: false,
                },
            ],
        },
        {
            id: 4,
            text: 'Combien de cigarettes fumez-vous par jour, en moyenne ?',
            goodAnswer: 1,
            isChecked: false,
            answerId: null,
            responses: [
                {
                    id: 1,
                    text: '10 ou moins',
                    isChecked: false,
                },
                {
                    id: 2,
                    text: '11 à 20',
                    isChecked: false,
                },
                {
                    id: 3,
                    text: '21 à 30',
                    isChecked: false,
                },
                {
                    id: 4,
                    text: '31 ou plus',
                    isChecked: false,
                },
            ],
        },
        {
            id: 5,
            text: 'Fumez-vous lorsque vous êtes malade au point de devoir rester au lit presque toute la journée ?',
            goodAnswer: 0,
            isChecked: false,
            answerId: null,
            responses: [
                {
                    id: 1,
                    text: 'Oui',
                    isChecked: false,
                },
                {
                    id: 2,
                    text: 'Non',
                    isChecked: false,
                },
            ],
        },
    ]);

    const handleResponse = (index, id, idQuestion) => {
        let newQuestions = [...questions];
        newQuestions[idQuestion - 1].isChecked = false;

        newQuestions[idQuestion - 1].responses[index].isChecked =
            !newQuestions[idQuestion - 1].responses[index].isChecked;
        newQuestions[idQuestion - 1].responses
            .filter((response) => response.id !== id)
            .map((response) => {
                newQuestions[idQuestion - 1].responses[
                    response.id - 1
                ].isChecked = false;
            });
        newQuestions[idQuestion - 1].answerId = index;

        setQuestions(newQuestions);
    };

    const handleReturn = () => {
        dispatch(closeGame());
        const contentModal = {
            id: 1,
            text: 'Maintenant que tu connais mieux Camille peut-être tu peux l’aider à remplir le test du médecin.',
            type: 'oh',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const finishFirstStep = () => {
        dispatch(closeModalTutorial());
        dispatch(nextStep());
    };

    const handleFinalFirstStep = () => {
        dispatch(closeModalIntroduction());
        dispatch(increment());
        const tutorialContents = [
            {
                id: 1,
                text: 'Bravo, tu as récupéré ton premier bout de fusée ! Tu te rapproches un peu plus du départ. Récupère la pièce de fusée correspondante et place-là sur le support.',
                type: 'win',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ffusee-1.png?alt=media&token=e1df4035-797a-4e9c-ac6f-e08196e2a48b',
                ],
            },
            {
                id: 2,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
                onClick: () => finishFirstStep(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleNextIntroCamille = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                "Il m’a alors proposé d'utiliser une machine capable de voyager dans le temps. Grâce à elle, nous allons retourner dans le passé ! ",
                'Nous allons ensemble modifier certains de mes choix.Je compte sur vous, aidez - moi à prendre les bonnes décisions.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleFinalFirstStep(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };
    const handleNextIntro = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Le médecin m’a annoncé que je ne pourrais pas partir en mission si je n’arrête pas de fumer. ',
                'Fumer dans l’espace ou faire une crise d’angoisse serait trop dangereux, beaucoup trop dangereux pour l’équipage.D’après lui, cette addiction au tabac remonte à ma jeunesse.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleNextIntroCamille(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleNextIntroduction = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Alors que se passe-t-il si Camille ne fume pas ? ',
                'Si Camille ne réalise pas son désir de fumer, cela va provoquer chez elle une sensation de manque. C’est-à-dire que le cerveau de Camille ne va pas recevoir ces petites molécules de plaisir et donc générer de la tristesse puis de la colère. Pour éviter que cela ne se produise, Camille ne va penser qu’à fumer, et aura du mal à se concentrer sur autre chose. C’est à ce moment-là que l’on parle d’addiction. ',
                'Vous l’avez compris son cerveau va ne faire que penser à la cigarette jusqu’à ce que Camille en trouve une et puisse la fumer.',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleNextIntro(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleNext = () => {
        dispatch(closeGame('phone'));
        const contentsModal = {
            contents: [
                'On parle d’addiction lorsque l’on ne peut plus se passer de quelque chose. Tout se passe dans le cerveau. Le circuit de la récompense se détraque: le cerveau de Camille a besoin de la cigarette pour ressentir du plaisir.',
                'Rien qu’à l’idée d’une cigarette, son cerveau va libérer des petites molécules de plaisir, c’est de la dopamine. Une fois la cigarette en bouche, son système de récompense est activé, son cerveau est satisfait et mémorise donc l’action de fumer comme positive. À force de répéter cette action, le cerveau de Camille va s’habituer à libérer toutes ces petites molécules de plaisir. ',
                'Le cerveau de Camille est piégé et associe le fait de fumer à un moment de détente...',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleNextIntroduction(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitResponse = (idQuestion) => {
        const isGoodAnswer =
            questions[idQuestion - 1].answerId ===
            questions[idQuestion - 1].goodAnswer;
        let newQuestions = [...questions];
        newQuestions[idQuestion - 1].isChecked = true;

        if (isGoodAnswer) {
            const nextQuestion = currentStep + 1;
            if (nextQuestion !== 6) {
                const contents = {
                    id: 1,
                    text: `( Question ${nextQuestion} ) Remplis le formulaire pour Camille..`,
                    type: 'smile',
                };

                setTimeout(() => {
                    setCurrentStep(nextQuestion);
                    dispatch(message(contents));
                }, 1000);
            } else {
                handleNext();
            }

            console.log(questions.length, nextQuestion);
        } else {
            const contents = {
                id: 1,
                text: "Tu as fait  une erreur mais ne t'inquiètes pas tu peux recommencer. ",
                type: 'bad',
            };
            dispatch(message(contents));
        }

        setQuestions(newQuestions);
    };

    const handleColor = (index, idQuestion) => {
        console.log(questions[idQuestion - 1].isChecked);
        if (
            questions[idQuestion - 1].isChecked &&
            index === questions[idQuestion - 1].answerId
        ) {
            console.log('fldzbf');
            if (index === questions[idQuestion - 1].goodAnswer) {
                return 'green';
            } else {
                return 'red';
            }
        }
        return '#000000';
    };

    console.log(questions);

    return (
        <div className="questions">
            {questions
                .filter((question) => question.id === currentStep)
                .map((question, indexQuestion) => (
                    <div>
                        <div className="questions-question">
                            <h3>{question.text}</h3>
                        </div>
                        <div className="questions-actions">
                            <div className="questions-responses">
                                {question.responses.map((response, index) => (
                                    <button
                                        className="questions-action"
                                        type="button"
                                        onClick={() =>
                                            handleResponse(
                                                index,
                                                response.id,
                                                question.id
                                            )
                                        }
                                    >
                                        <div
                                            className={`${
                                                response.isChecked
                                                    ? 'questions-check'
                                                    : 'questions-not-check'
                                            }`}
                                        />
                                        <p
                                            style={{
                                                color: handleColor(
                                                    index,
                                                    question.id
                                                ),
                                            }}
                                        >
                                            {response.text}
                                        </p>
                                    </button>
                                ))}
                            </div>
                            <div className="questions-buttons">
                                <Button
                                    title="Valider"
                                    onClick={() => submitResponse(question.id)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default Questions;

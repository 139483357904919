import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contents: [],
  type: null,
  isOpen: false,
  isChecked: false,
  onClick: null,
  buttonText: '',
}


export const modalIntroductionSlice = createSlice({
  name: 'modalIntroduction',
  initialState,
  reducers: {
    openModalIntroduction: (state, action) =>
    {
      state.contents = action.payload.contents
      state.type = action.payload.type
      state.isOpen = true
      state.buttonText = action.payload.buttonText
      state.onClick = action.payload.onClick
    },
    closeModalIntroduction: (state) =>
    {
      console.log('test')
      state.contents = []
      state.type = null
      state.isOpen = false
      state.onClick= null
      state.buttonText= ''
    },
    checkedModalIntroduction: (state) => {
      state.isChecked = true
    },
  },
})

export const { openModalIntroduction, closeModalIntroduction, checkedModalIntroduction } = modalIntroductionSlice.actions

export const selectModalIntroduction = (state) => state.modalIntroduction


export default modalIntroductionSlice.reducer

import React from 'react';
import { useSelector } from 'react-redux';

import { selectGames } from './gamesSlice';

import TutorialRobot from '../reusableComponents/modal/modalTutorial/tutorialRobot/TutorialRobot';
import Cigaret from '../games/cigaret/Cigaret';
import Dictaphone from './didactphone/Dictaphone';
import Questions from './questions/Questions';
import Phone from './phone/Phone';
import Tiroir from './tiroir/Tiroir';
import Puzzle from './puzzle/Puzzle';
import Book from './book/Book';
import Tablette from './tablette/Tablette';
import Android from './android/Android';
import Color from './color/Color';
import Mirror from './mirror/Mirror';
import Clock from './clock/Clock';
import Tabac from './tabac/Tabac';
import Constellation from './constellation/Constellation';

import '../games/games.css';
import ButtonReturn from '../reusableComponents/button/ButtonReturn';

const Games = () => {
    const games = useSelector(selectGames);

    const handleGames = () => {
        switch (games.currentGame) {
            case 'cigaret':
                return (
                    <>
                        <Cigaret />
                    </>
                );
            case 'dictaphone':
                return (
                    <>
                        <Dictaphone />
                        <ButtonReturn />
                    </>
                );
            case 'questions':
                return (
                    <>
                        <Questions />
                        <ButtonReturn />
                    </>
                );
            case 'phone':
                return (
                    <>
                        <Phone />
                        <ButtonReturn />
                    </>
                );
            case 'tiroir':
                return (
                    <>
                        <Tiroir />
                        <ButtonReturn />
                    </>
                );
            case 'tablette':
                return (
                    <>
                        <Tablette />
                        <ButtonReturn />
                    </>
                );
            case 'puzzle':
                return (
                    <>
                        <Puzzle />
                        <ButtonReturn />
                    </>
                );
            case 'book':
                return (
                    <>
                        <Book />
                        <ButtonReturn />
                    </>
                );
            case 'android':
                return (
                    <>
                        <Android />
                    </>
                );
            case 'color':
                return (
                    <>
                        <Color />
                        <ButtonReturn />
                    </>
                );
            case 'mirror':
                return (
                    <>
                        <Mirror />
                    </>
                );
            case 'clock':
                return (
                    <>
                        <Clock />
                        <ButtonReturn />
                    </>
                );
            case 'tabac':
                return (
                    <>
                        <Tabac />
                        <ButtonReturn />
                    </>
                );
            case 'constellation':
                return (
                    <>
                        <Constellation />
                    </>
                );
            default:
                break;
        }
    };

    return (
        <div className="games">
            <div className="games-background">{handleGames()}</div>
            <TutorialRobot contentModal={games.contents} />
        </div>
    );
};

export default Games;

import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'

import {
    selectScore
} from './scoreSlice'
  
import './score.css';

const Score = () =>
{
    const score = useSelector(selectScore)
    
    const [rockets, setRockets] = useState([])

    useEffect(() =>
    {
            let newRockets = [...rockets];

            switch (score.value) {
                case 1:
                    newRockets.push([])
                    break;
                case 2:
                    newRockets.push([1])
                    break;
                case 3:
                    newRockets.push([1, 1])
                    break;
                case 4:
                    newRockets.push([1, 1, 1])
                    break;
                case 5:
                    newRockets.push([1, 1, 1, 1])
                    break
                default:
                    break;
            }
            setRockets(newRockets)
        

    }, [score])

    return (
        <div className="score">
            <div className="score-rockets">
                {rockets.map(scoreElement => <img src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fheader%2Frocket.svg?alt=media&token=55065bc3-d263-489d-8d9b-c432afc564b6" alt="fusée" />)}
            </div>
        </div>
    )
}

export default Score;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import { closeGame, message } from '../gamesSlice';

import './dictaphone.css';

const Dictaphone = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const handleNext = () => {
        dispatch(closeGame());
        const contentModal = {
            id: 1,
            text: 'Maintenant que tu connais mieux Camille peut-être tu peux l’aider à remplir le test du médecin.',
            type: 'oh',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handlePlay = () => {
        setIsOpen(true);
        const contents = {
            id: 1,
            text: 'Ce message semble être plein d’informations sur le comportement de Camille vis-à-vis de la cigarette.',
            type: 'smile',
            textButton: 'Suivant',
            onClick: () => handleNext(),
        };
        dispatch(message(contents));
    };
    return (
        <div className="Dictaphone">
            {!isOpen ? (
                <button type="button" onClick={() => handlePlay()}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fdictaphone%2Fdictaphone-off.svg?alt=media&token=16f583ec-be37-484b-bf60-bf254b9e41e8"
                        alt="dictaphone play"
                    />
                </button>
            ) : (
                <div className="Dictaphone-open">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fdictaphone%2Fdictaphone-on-2.svg?alt=media&token=3825f177-01fd-41ef-b022-330529647809"
                        alt="dictaphone play"
                    />
                    <div className="Dictaphone-open-text">
                        <p>
                            “ Depuis des semaines, la première chose que je fais
                            en me levant, c’est fumer une cigarette. Celle du
                            matin, je ne pourrais pas m’en passer… puis de
                            toutes les autres non plus d’ailleurs. Même quand je
                            suis malade, je ne peux pas m’empêcher de fumer.{' '}
                        </p>
                        <p>
                            C’est vrai que ça devient difficile de m’en passer.
                            Au travail, au restaurant, il faut que je sorte pour
                            fumer, parce que j’en ressens vraiment le besoin. Si
                            on fait le compte, j’en viens à fumer 12 cigarettes
                            par jour ! Ça fait beaucoup non ? ”
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dictaphone;

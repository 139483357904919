import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import scoreReducer from '../features/reusableComponents/header/score/scoreSlice';
import modalIntroductionReducer from '../features/reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import modalTutorialReducer from '../features/reusableComponents/modal/modalTutorial/modalTutorialSlice';
import stepsReducer from '../features/steps/StepsSlice';
import codeMachineReducer from '../features/reusableComponents/codeMachine/codeMachineSlice';
import gamesReducer from '../features/games/gamesSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    score: scoreReducer,
    modalIntroduction: modalIntroductionReducer,
    modalTutorial: modalTutorialReducer,
    steps: stepsReducer,
    codeMachine: codeMachineReducer,
    games: gamesReducer,
  }
})

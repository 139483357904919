import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Introduction from './features/introduction/Introduction'
import Home from './features/home/Home'


import './App.css'

function App () {
  return (
    <div className='app'>
      <Routes>
        <Route path='/introduction' element={<Introduction />} />
        <Route path='/' element={<Home />} />

      </Routes>
    </div>
  )
}

export default App

import React from "react";

import '../../../tutorial.css';

const BigTv = ({ images, id, content}) =>
{
    return (
        <div className="tutorial">
            <img alt='tutorial' className="tutorial-content" src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ftv-grande.svg?alt=media&token=c0ede63c-ffca-42c0-a079-7d5e54df1369" />
            {images.map(imageContent => <img className="tutorial-element" src={imageContent} alt={`tutoriel-${ id }`} />)}
        </div>
    )
    
}

export default BigTv;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../reusableComponents/button/Button';
import ButtonReturn from '../../reusableComponents/button/ButtonReturn';
import { increment } from '../../reusableComponents/header/score/scoreSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { nextStep } from '../../steps/StepsSlice';
import { closeGame, message } from '../gamesSlice';
import './constellation.css';

const Constellation = () => {
    const dispatch = useDispatch();
    const [input, setInput] = useState('');

    const finishGame = () => {
        dispatch(closeModalTutorial());
        dispatch(nextStep());
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        dispatch(increment());
        const tutorialContents = [
            {
                id: 1,
                text: 'Super, tu as récupéré ton dernier bout de fusée !  L’heure du départ est arrivée, attention au décollage. Récupère la pièce de fusée correspondante et place-là sur le support.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ffuseee-4.png?alt=media&token=b0d177af-98f4-4c06-87e0-db08de285264',
                ],
            },
            {
                id: 2,
                text: 'Avant que l’on ne se quitte, réponds aux questions du livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
                onClick: () => finishGame(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleIntroductionFour = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Je n’ai plus le besoin de m’enfermer dans cette mauvaise habitude qu’est la cigarette. ',
                'Tout ça, c’est fini, à moi la conquête de l’espace !',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroductionThree = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Au fil des différents moments de vie parcourus ensemble, j’ai pris conscience que ce qui compte, c’est avant tout l’estime que j’ai de moi.',
                'Fini d’imaginer ce que les gens pensent de moi et de mes passions.J’assume pleinement qui je suis, que ça leur plaise ou non.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionFour(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroductionTwo = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Merci pour votre aide ! En remontant dans le temps et en me proposant de modifier mes choix, vous m’avez aidé à comprendre pourquoi j’ai commencé à fumer.',
                'En plus de ça, vous m’avez apporté des solutions pour me sentir plus forte et plus libre, sans gâcher ma santé.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionThree(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroduction = () => {
        dispatch(closeGame('cigaret'));
        const contentsModal = {
            contents: [
                'Pour s’intégrer dans un groupe de camarades, Camille se sent obligée de pratiquer les mêmes loisirs et répéter les mêmes actions qu’eux. C’est ce qu’on appelle la pression sociale.',
                'En effet, pour se sentir acceptée, elle va imiter les attitudes des personnes qu’elle trouve les plus cool.',
                'Par exemple, Camille a commencé à fumer pour se rapprocher du groupe des fumeurs car elle enviait leur attitude rebelle et elle avait l’impression que rien ne pouvait les toucher.',
                'Pourtant, cette attitude ne signifie pas que nous sommes plus libres et forts… bien au contraire !',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionTwo(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const submitConstellation = () => {
        const goodResponse = input === 'no' || input === 'NO';

        if (goodResponse) {
            const contents = {
                id: 1,
                text: 'Félicitations ! Tu as résolu la dernière carte machine. Mais laisse moi te parler un peu de l’intégration dans un groupe.',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleIntroduction(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Malheureusement, ça ne semble pas correspondre à la solution. Tu es sur de bien avoir relié les points indiqués?',
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };
    return (
        <div className="constellation">
            <div className="constellation_content">
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fstars%2Fetoiles-full-1.svg?alt=media&token=9a929ba3-3efb-441a-b196-e4fa9c3217ec"
                    alt="fonds etoiles"
                />
            </div>
            <input
                onChange={(e) => setInput(e.target.value)}
                style={{
                    backgroundImage:
                        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-bkg.svg?alt=media&token=6f4f64c9-c2c2-499e-a6ed-aac2f61923a0',
                }}
                type="text"
                maxLength="5"
            />
            <div className="constellation_buttons">
                <ButtonReturn />
                <Button title="Valider" onClick={() => submitConstellation()} />
            </div>
        </div>
    );
};

export default Constellation;

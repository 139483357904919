import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';

import { closeGame, message } from '../gamesSlice';

import './color.css';
const Color = () => {
    const dispatch = useDispatch();

    const [colors, setColors] = useState([
        {
            id: 1,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-rouge.svg?alt=media&token=84aeffd5-4b8e-466f-b3fa-d2319f670e71',
            text: ' ',
            good: 'i',
            isChecked: false,
        },
        {
            id: 2,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-orange.svg?alt=media&token=08dc16aa-126e-45b5-b336-dfbcfff13e2c',
            text: ' ',
            good: 'a',
            isChecked: false,
        },
        {
            id: 3,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-vert.svg?alt=media&token=aaad43bc-19fd-4ce8-a7d7-3cfc552a3c49',
            text: ' ',
            good: 'o',
            isChecked: false,
        },
        {
            id: 4,
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-bleu.svg?alt=media&token=0e3d173b-ee06-4414-9512-e2f755ed1e19',
            text: ' ',
            good: 'u',
            isChecked: false,
        },
    ]);

    const handleText = (index, value, content) => {
        const newColors = [...colors];
        newColors[index].text = value;

        if (value === content.good) {
            newColors[index].isChecked = true;
        }
        setColors(newColors);
    };

    const handleAlertImage = (content) => {
        if (content.text !== ' ') {
            if (content.isChecked) {
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fvoyant-green-1.svg?alt=media&token=93c267aa-f0a9-431f-adde-5e1ebc94303e';
            } else {
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fvoyant-red-1.svg?alt=media&token=984b6f1f-29b7-4170-ba28-6d56a3836725';
            }
        } else {
            return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fvoyant-off-1.svg?alt=media&token=22003d6d-6527-49fa-8c3b-814ae80a0c43';
        }
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleNext = () => {
        dispatch(closeGame('mirror'));
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 2,
                text: 'Pour poursuivre le jeu pioche les cartes indiquées.',
                type: 'smile',
                content: 'pickUp',
                card: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes8.png?alt=media&token=7b04e863-5d7e-4250-85ea-27b27f363118',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    useEffect(() => {
        const isGoodResponse = colors.find(
            (colorContent) => !colorContent.isChecked
        );

        if (isGoodResponse === undefined) {
            const contents = {
                id: 1,
                text: 'Bravo tu as réussi!',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        }
    }, [colors]);

    return (
        <div className="color">
            <div className="color_content">
                {colors.map((colorContent, index) => (
                    <button type="button" className="color_content-button">
                        <img
                            className="color_content-image"
                            src={handleAlertImage(colorContent)}
                            alt={`check-${colorContent.id}`}
                        />
                        {!colorContent.isChecked ? (
                            <input
                                onChange={(e) =>
                                    handleText(
                                        index,
                                        e.target.value,
                                        colorContent
                                    )
                                }
                                style={{
                                    backgroundImage: `url(${colorContent.url})`,
                                }}
                                type="text"
                                id={colorContent.id}
                                name={colorContent.id}
                                maxLength="1"
                            />
                        ) : (
                            <p
                                style={{
                                    backgroundImage: `url(${colorContent.url})`,
                                }}
                            >
                                {colorContent.text}
                            </p>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Color;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeGame, message } from '../gamesSlice';

import Button from '../../reusableComponents/button/Button';

import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';

import './android.css';
import ButtonReturn from '../../reusableComponents/button/ButtonReturn';

const Android = () => {
    const dispatch = useDispatch();

    const [responses, setResponses] = useState([]);
    const [firstLine, setFirstLine] = useState([
        {
            id: 1,
            isChecked: false,
        },
        {
            id: 2,
            isChecked: false,
        },
        {
            id: 3,
            isChecked: false,
        },
        {
            id: 4,
            isChecked: false,
        },
        {
            id: 5,
            isChecked: false,
        },
    ]);

    const [secondLine, setSecondLine] = useState([
        {
            id: 6,
            isChecked: false,
        },
        {
            id: 7,
            isChecked: false,
        },
        {
            id: 8,
            isChecked: false,
        },
        {
            id: 9,
            isChecked: false,
        },
        {
            id: 10,
            isChecked: false,
        },
    ]);

    const [thirdLine, setThirdLine] = useState([
        {
            id: 11,
            isChecked: false,
        },
        {
            id: 12,
            isChecked: false,
        },
        {
            id: 13,
            isChecked: false,
        },
        {
            id: 14,
            isChecked: false,
        },
        {
            id: 15,
            isChecked: false,
        },
    ]);

    const [fourthLine, setFourthLine] = useState([
        {
            id: 16,
            isChecked: false,
        },
        {
            id: 17,
            isChecked: false,
        },
        {
            id: 18,
            isChecked: false,
        },
        {
            id: 19,
            isChecked: false,
        },
        {
            id: 20,
            isChecked: false,
        },
    ]);

    const handleCode = (index, type, id) => {
        switch (type) {
            case 'firstLine':
                let newFirstLine = [...firstLine];
                newFirstLine[index].isChecked = !newFirstLine[index].isChecked;
                setFirstLine(newFirstLine);

                break;

            case 'secondLine':
                let newSecondLine = [...secondLine];
                newSecondLine[index].isChecked =
                    !newSecondLine[index].isChecked;
                setSecondLine(newSecondLine);

                break;
            case 'thirdLine':
                let newThirdLine = [...thirdLine];
                newThirdLine[index].isChecked = !newThirdLine[index].isChecked;
                setThirdLine(newThirdLine);

                break;
            case 'fourthLine':
                let newFourthLine = [...fourthLine];
                newFourthLine[index].isChecked =
                    !newFourthLine[index].isChecked;
                setFourthLine(newFourthLine);

                break;

            default:
                break;
        }

        let newArrayChecked = [...responses];
        const isElementInArraychecked = newArrayChecked.find(
            (element) => element === id
        );

        if (isElementInArraychecked === undefined) {
            newArrayChecked.push(id);
            setResponses(newArrayChecked);
        } else {
            const indexToDelete = newArrayChecked.findIndex(
                (element) => element === id
            );
            newArrayChecked.splice(indexToDelete, 1);
            setResponses(newArrayChecked);
        }
    };

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code...',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleIntroduction = () => {
        dispatch(closeModalTutorial());
        const contentsModal = {
            contents: [
                'Revoir tous ces commentaires me fait un peu bizarre… ',
                'Certains sont négatifs, mais finalement, il y en a beaucoup de positifs aussi.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleNext = () => {
        dispatch(closeGame('color'));
        const tutorialContents = [
            {
                id: 1,
                text: 'Tu peux maintenant avoir accès à tous les commentaires de la vidéo sur les planètes. Pour cela, récupère les cartes indiquées.',
                type: 'smile',
                content: 'pickUp',
                card: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes7.png?alt=media&token=015a0f36-f301-4542-babd-9db1d528f340',
                ],
                onClick: () => handleIntroduction(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const submitResponse = () => {
        const goodResponse = [4, 5, 9, 10, 13, 16, 17];
        responses.sort((firstElement, secondElement) => {
            return firstElement - secondElement;
        });

        const goodResponseString = goodResponse.toString();
        const responseString = responses.toString();

        console.log(responses);
        if (goodResponseString === responseString) {
            const contents = {
                id: 1,
                text: 'Encore une énigme résolue ! Grâce à toi on va pouvoir avoir accès au téléphone de Camille.',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        } else {
            const newFirstLine = [...firstLine];
            newFirstLine.map((elementLine) => (elementLine.isChecked = false));
            setFirstLine(newFirstLine);

            const newSecondLine = [...secondLine];
            newSecondLine.map((elementLine) => (elementLine.isChecked = false));
            setSecondLine(newSecondLine);

            const newThirdLine = [...thirdLine];
            newThirdLine.map((elementLine) => (elementLine.isChecked = false));
            setThirdLine(newThirdLine);

            const newFourthLine = [...fourthLine];
            newFourthLine.map((elementLine) => (elementLine.isChecked = false));
            setFourthLine(newFourthLine);

            const contents = {
                id: 1,
                text: "Zut tu t’es trompé. Mais ne t'inquiètes pas tu peux réessayer autant que tu veux.",
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };

    return (
        <div className="android">
            <div className="android_content">
                <div className="android_content-buttons">
                    <div className="android_content-buttons-line">
                        {firstLine.map((elementRound, index) => (
                            <button
                                className={`android_content-button ${
                                    elementRound.isChecked
                                        ? 'checked'
                                        : 'not-checked'
                                }`}
                                onClick={() =>
                                    handleCode(
                                        index,
                                        'firstLine',
                                        elementRound.id
                                    )
                                }
                            />
                        ))}
                    </div>
                    <div className="android_content-buttons-line">
                        {secondLine.map((elementRound, index) => (
                            <button
                                className={`android_content-button ${
                                    elementRound.isChecked
                                        ? 'checked'
                                        : 'not-checked'
                                }`}
                                onClick={() =>
                                    handleCode(
                                        index,
                                        'secondLine',
                                        elementRound.id
                                    )
                                }
                            />
                        ))}
                    </div>
                    <div className="android_content-buttons-line">
                        {thirdLine.map((elementRound, index) => (
                            <button
                                className={`android_content-button ${
                                    elementRound.isChecked
                                        ? 'checked'
                                        : 'not-checked'
                                }`}
                                onClick={() =>
                                    handleCode(
                                        index,
                                        'thirdLine',
                                        elementRound.id
                                    )
                                }
                            />
                        ))}
                    </div>
                    <div className="android_content-buttons-line">
                        {fourthLine.map((elementRound, index) => (
                            <button
                                className={`android_content-button ${
                                    elementRound.isChecked
                                        ? 'checked'
                                        : 'not-checked'
                                }`}
                                onClick={() =>
                                    handleCode(
                                        index,
                                        'fourthLine',
                                        elementRound.id
                                    )
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <Button title="Valider" onClick={() => submitResponse()} />
                <ButtonReturn />
            </div>
        </div>
    );
};

export default Android;

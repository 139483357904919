import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    isChecked: false,
    contents: [],
}


export const modalTutorialSlice = createSlice({
  name: 'modalTutorial',
  initialState,
  reducers: {
    openModalTutorial: (state, action) =>
    {
          state.isOpen = true
          state.contents = action.payload
    },
    closeModalTutorial: (state) => {
      state.isOpen = false
    },
  },
})

export const { openModalTutorial, closeModalTutorial } = modalTutorialSlice.actions

export const selectModalTutorial = (state) => state.modalTutorial


export default modalTutorialSlice.reducer

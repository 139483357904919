import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import ButtonReturn from '../../reusableComponents/button/ButtonReturn';
import { increment } from '../../reusableComponents/header/score/scoreSlice';
import {
    closeModalIntroduction,
    openModalIntroduction,
} from '../../reusableComponents/modal/modalIntroduction/modalIntroductionSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { nextStep } from '../../steps/StepsSlice';
import { closeGame, message } from '../gamesSlice';

import './mirror.css';

const Mirror = () => {
    const dispatch = useDispatch();

    const [image, setImage] = useState(
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fmirror%2Fmiroir-sans-texte.svg?alt=media&token=60007125-8e48-4be8-8883-41e5513ad0a4'
    );
    const [isButton, setIsButton] = useState(false);
    const [currentDisplay, setCurrentDisplay] = useState(0);
    const [position, setPosition] = useState(false);

    const [text, setText] = useState([
        {
            id: 1,
            text: 'La nouvelle Camille est arrivée',
            isDisplay: false,
        },
        {
            id: 2,
            text: 'Après tant d’années à douter d’elle et à penser qu’elle n’est bonne à rien',
            isDisplay: false,
        },
        {
            id: 3,
            text: 'L’estime qu’elle a d’elle-même s’est essoufflée',
            isDisplay: false,
        },
        {
            id: 4,
            text: 'Il serait fou de penser que',
            isDisplay: false,
        },
        {
            id: 5,
            text: 'Camille est capable de changer',
            isDisplay: false,
        },
        {
            id: 6,
            text: 'Comme vous l’imaginez',
            isDisplay: false,
        },
        {
            id: 7,
            text: 'Elle finit toujours par abandonner',
            isDisplay: false,
        },

        {
            id: 8,
            text: 'Il faut arrêter de penser que',
            isDisplay: false,
        },

        {
            id: 9,
            text: 'Camille est fière de ce qu’elle accomplit',
            isDisplay: false,
        },

        {
            id: 10,
            text: 'Certains disent même que',
            isDisplay: false,
        },

        {
            id: 11,
            text: 'Camille n’est pas capable de bien faire les choses',
            isDisplay: false,
        },

        {
            id: 12,
            text: 'Il est donc difficile de croire que',
            isDisplay: false,
        },

        {
            id: 13,
            text: 'En termes d’affirmation et de confiance en soi',
            isDisplay: false,
        },

        {
            id: 14,
            text: 'Cette nouvelle Camille est vraiment quelqu’un de bien !',
            isDisplay: false,
        },
    ]);

    const finishSecondStep = () => {
        dispatch(closeModalTutorial());
        dispatch(nextStep());
    };

    const handleTutorial = () => {
        dispatch(closeModalIntroduction());
        dispatch(increment());

        const tutorialContents = [
            {
                id: 1,
                text: 'Bravo, tu as récupéré ton troisième bout de fusée !  Tu te rapproches un peu plus du départ. Récupère la pièce de fusée correspondante et place-là sur le support.',
                type: 'win',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ffusee-3.png?alt=media&token=514d32b8-9688-4c9f-a547-d93505204de8',
                ],
            },
            {
                id: 3,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
                onClick: () => finishSecondStep(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleIntroductionFive = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Mais plus maintenant.Je persévère.Et je suis d’autant plus contente de moi après coup.Et quand j’ai l’impression de douter de moi, j’en parle avec ceux qui me soutiennent et en qui j’ai confiance.',
                'Il faut donc rester positif et éliminer au maximum les idées négatives! ',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleTutorial(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroductionFour = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Fêter mes petites victoires m’a pas mal aidé aussi, même si ça peut paraître bête au début… Mais un exercice de maths réussi, même s’il est facile, ça reste une réussite.',
                'Lorsque je rencontrais des difficultés, je baissais vite les bras. ',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionFive(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroductionThree = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                "Je n’avais pas vraiment une bonne estime de moi-même avant, mais ça c'était avant ! Avec le temps et en grandissant, j’ai appris à reconnaître mes qualités et à les mettre en avant, j’aurais pu le faire avant ! ",
                'Il est très important de croire en ses capacités, et il faut en être fier.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionFour(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroductionTwo = () => {
        dispatch(closeModalIntroduction());
        const contentsModal = {
            contents: [
                'Pourquoi on se focalise toujours sur les avis négatifs des gens…? Au fond, plaire à tout le monde, cela ne sert à rien et ça n’apporte rien non plus.',
                'J’aurais dû me focaliser sur les commentaires positifs postés sous ma vidéo.',
            ],
            type: 'left',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionThree(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    const handleIntroduction = () => {
        dispatch(closeGame('clock'));
        const contentsModal = {
            contents: [
                'L’estime de soi, c’est l’image et la valeur que l’on a de soi-même. Très souvent, chez les enfants, l’aspect physique, la réussite scolaire, les compétences en sport ou encore la popularité vont influencer la confiance en soi.',
                'Au cours de la vie et en fonction des épreuves et événements traversés, il est normal que l’estime de soi augmente ou diminue.Elle augmentera après la réussite de certaines choses et diminuera en cas d’échec.Mais un échec n’est pas synonyme de défaite.C’est justement l’occasion de montrer  aux autres que l’on est capable de se relever et de faire encore mieux!',
                'Avoir une bonne estime de soi permet de prendre de l’assurance et d’avoir plus confiance en soi, mais surtout de se sentir bien dans sa tête et dans son corps. ',
            ],
            type: 'right',
            buttonText: 'Suivant',
            onClick: () => handleIntroductionTwo(),
        };
        dispatch(openModalIntroduction(contentsModal));
    };

    useEffect(() => {
        console.log(currentDisplay);

        setTimeout(() => {
            if (currentDisplay !== 13 && !position) {
                setCurrentDisplay(currentDisplay + 1);
            }

            if (currentDisplay !== 0 && position) {
                setCurrentDisplay(currentDisplay - 1);
            }
        }, 3000);

        if (currentDisplay === 13 && !position) {
            setTimeout(() => {
                setIsButton(true);
                setImage(
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fmirror%2Fmiroir-broken.svg?alt=media&token=b39f95c0-55db-4891-8e29-b343c631df2c'
                );

                const contents = {
                    id: 1,
                    text: 'Oh non le miroir est complètement brisé. Essaye d’associer les morceaux pour le reconstituer.',
                    type: 'bad',
                };
                dispatch(message(contents));
            }, 4000);
        }

        if (currentDisplay === 0 && position) {
            setTimeout(() => {
                const contents = {
                    id: 1,
                    text: 'Tu as remarqué, lu dans l’autre sens, le texte est devenu beaucoup plus positif.',
                    type: 'smile',
                    textButton: 'Suivant',
                    onClick: () => handleIntroduction(),
                };
                dispatch(message(contents));
            }, 4000);
        }
    }, [currentDisplay]);

    const handleMirror = () => {
        setImage(
            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fmirror%2Fmiroir-sans-texte.svg?alt=media&token=60007125-8e48-4be8-8883-41e5513ad0a4'
        );
        const contents = {
            id: 1,
            text: 'Super tu as réussi à compléter le miroir !',
            type: 'win',
        };
        setIsButton(false);
        dispatch(message(contents));
        setPosition(true);

        const newText = [...text];
        newText
            .filter((newtTextContent) => newtTextContent.id === 14)
            .map((newtTextContent) => (newtTextContent.isDisplay = false));
        setText(newText);
        setTimeout(() => {
            const contents = {
                id: 1,
                text: 'Regarde, du texte apparaît dans le miroir...',
                type: 'win',
            };
            setCurrentDisplay(currentDisplay - 1);

            dispatch(message(contents));
        }, 1000);
    };

    const handleDisplay = (index) => {
        if (!position) {
            if (index <= currentDisplay) {
                return 'block';
            } else {
                return 'none';
            }
        } else {
            if (index >= currentDisplay) {
                return 'block';
            } else {
                return 'none';
            }
        }
    };
    return (
        <div className="mirror">
            <div
                style={{ backgroundImage: `url(${image})` }}
                className="mirror_content"
            >
                {text.map((currentText, index) => (
                    <p style={{ display: handleDisplay(index) }}>
                        {currentText.text}
                    </p>
                ))}
            </div>
            <div>
                {isButton && (
                    <Button title="Réparer" onClick={() => handleMirror()} />
                )}
                <ButtonReturn />
            </div>
        </div>
    );
};
export default Mirror;

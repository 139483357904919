import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../reusableComponents/button/Button';
import { closeGame, initGame, message } from '../gamesSlice';

import './tiroir.css';
import ButtonReturn from '../../reusableComponents/button/ButtonReturn';

const Tiroir = () => {
    const dispatch = useDispatch();
    const [inputsLock, setInputsLock] = useState([0, 0, 0, 0]);

    const handleInputLock = (elementLock, index, type) => {
        const newInputsLock = [...inputsLock];

        if (elementLock === 9 && type !== 'down') {
            newInputsLock[index] = 0;
        } else if (elementLock === 0 && type !== 'up') {
            newInputsLock[index] = 9;
        } else {
            if (type === 'up') {
                newInputsLock[index] = elementLock + 1;
            } else {
                newInputsLock[index] = elementLock - 1;
            }
        }

        setInputsLock(newInputsLock);
    };

    const listLock = [
        {
            name: '0',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-0.svg?alt=media&token=ec2fd561-5e72-4a37-b2dd-b2ca140b1646',
        },
        {
            name: '1',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-1.svg?alt=media&token=28ab0aa2-0406-4f4c-a8a2-29e8a024ff7b',
        },
        {
            name: '2',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-2.svg?alt=media&token=2bd2ef72-466f-4aa8-be7f-b5a1fa9f6252',
        },
        {
            name: '3',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-3.svg?alt=media&token=f53d4ae6-27df-4ac0-bd76-43a598b4d0db',
        },
        {
            name: '4',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-4.svg?alt=media&token=a59a4051-fde0-4678-a364-b4b1ca19126a',
        },
        {
            name: '5',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-5.svg?alt=media&token=f12fd471-d4ff-4e2a-bda6-e7132c34059b',
        },
        {
            name: '6',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-6.svg?alt=media&token=6312828b-514a-48ec-bde1-fd47c87a405f',
        },
        {
            name: '7',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-7.svg?alt=media&token=42be88ca-736d-4818-aea8-8e67b8bd3717',
        },
        {
            name: '8',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-8.svg?alt=media&token=4d5cfaab-c398-4dbd-b675-530c5096e15e',
        },
        {
            name: '9',
            url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-carre-9.svg?alt=media&token=8ce3fbf5-e3fe-4ee1-a37f-43fe709cdf2b',
        },
    ];

    const handleNext = () => {
        dispatch(closeGame('tablette'));
        const contentModal = {
            contents: {
                id: 1,
                text: 'Une tablette se trouve à l’intérieur du tiroir! On dirait encore une vieille vidéo de Camille mais il semblerait que celle-ci n’ai jamais été publiée. Appuie sur le bouton lecture pour la visionner.',
                type: 'smile',
            },
            gameSlug: 'tablette',
        };
        dispatch(initGame(contentModal));
    };

    const sumbmitLock = () => {
        const goodAnswer = [1, 3, 0, 9];
        const goodAnswerString = goodAnswer.toString();
        const inputsLockString = inputsLock.toString();

        const openLock = inputsLockString === goodAnswerString;

        if (openLock) {
            const contents = {
                id: 1,
                text: 'Bravo ! Tu as réussi à ouvrir le tiroir. Il semblerait qu’il y est une tablette à l’intérieur...',
                type: 'win',
                textButton: 'Suivant',
                onClick: () => handleNext(),
            };
            dispatch(message(contents));
        } else {
            const contents = {
                id: 1,
                text: 'Attention tu as fais une erreur.  Réessaie !',
                type: 'bad',
            };
            dispatch(message(contents));
        }
    };
    return (
        <div className="tiroir">
            <div className="tiroir_contents">
                {inputsLock.map((inputLock, index) => (
                    <div className="tiroir_content">
                        <button
                            className="tiroir_arrow up"
                            type="button"
                            onClick={() =>
                                handleInputLock(inputLock, index, 'up')
                            }
                        >
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-fleche.svg?alt=media&token=0cef0035-a6e4-45ef-8035-f777d5092dcd"
                                alt="fleche haut"
                            />
                        </button>
                        <button className="tiroir_button" type="button">
                            <img
                                src={listLock[inputLock].url}
                                alt={listLock[inputLock].name}
                            />
                            <button
                                className="tiroir_arrow down"
                                type="button"
                                onClick={() =>
                                    handleInputLock(inputLock, index, 'down')
                                }
                            >
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-fleche.svg?alt=media&token=0cef0035-a6e4-45ef-8035-f777d5092dcd"
                                    alt="fleche haut"
                                />
                            </button>
                        </button>
                    </div>
                ))}
            </div>
            <Button title="Valider" onClick={() => sumbmitLock()} />
        </div>
    );
};

export default Tiroir;

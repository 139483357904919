import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openCodeMachine } from '../../reusableComponents/codeMachine/codeMachineSlice';
import {
    closeModalTutorial,
    openModalTutorial,
} from '../../reusableComponents/modal/modalTutorial/modalTutorialSlice';
import { closeGame, message } from '../gamesSlice';

import './tablette.css';

const Tablette = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const handleCodeMachine = () => {
        dispatch(closeModalTutorial());
        const contentModal = {
            id: 1,
            text: 'En attente d’un code... Petite astuce, tu peux utiliser une forme avec différents  motifs.',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    const handleNext = () => {
        dispatch(closeGame('puzzle'));
        const tutorialContents = [
            {
                id: 1,
                text: 'Avant de passer à la prochaine énigme, réponds aux questions qui se trouvent dans le livret.',
                type: 'smile',
                content: 'tv',
                images: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fcapsule%2Flivre.png?alt=media&token=cc4b800f-93c1-4239-bebe-14b26f07ae08',
                ],
            },
            {
                id: 2,
                text: 'Pour poursuivre le jeu pioche les cartes indiquées.',
                type: 'smile',
                content: 'pickUp',
                card: [
                    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fcards%2Fcapsule%2Fcartes4.png?alt=media&token=f45b5f11-fabd-407f-bcdd-f80557c972f2',
                ],
                onClick: () => handleCodeMachine(),
            },
        ];
        dispatch(openModalTutorial(tutorialContents));
    };

    const handleClick = () => {
        setIsOpen(true);
        const contents = {
            id: 1,
            text: 'Une tablette se trouve à l’intérieur du tiroir ! On dirait encore une vieille vidéo de Camille mais il semblerait que celle-ci n’ai jamais été publiée. Appuie sur le bouton lecture pour la visionner.',
            textButton: 'Suivant',
            onClick: () => handleNext(),
        };
        dispatch(message(contents));
    };
    return (
        <div className="tablette">
            {!isOpen ? (
                <button
                    type="button"
                    className="tablette_button"
                    onClick={() => handleClick()}
                >
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftablette%2Ftab-off-full.svg?alt=media&token=2dff50e3-b379-4354-b9f8-05e5a98c02e4"
                        alt="tablette-on"
                    />
                </button>
            ) : (
                <div className="tablette_open">
                    <p>
                        “ Je ne comprends pas pourquoi tout le monde s’est moqué
                        de moi... Qu’est-ce que j’ai fait de mal ? Je voulais
                        juste faire une vidéo pour montrer ma passion pour
                        l’astronomie. Mais il y avait que des commentaires
                        négatifs sous ma vidéo. Cela m’a rendue triste et puis
                        voilà, après j’avais honte.”
                    </p>
                </div>
            )}
        </div>
    );
};
export default Tablette;

import Button from '../../reusableComponents/button/Button';
import { closeGame } from '../../games/gamesSlice';
import { openCodeMachine } from '../codeMachine/codeMachineSlice';
import { useDispatch } from 'react-redux';

const ButtonReturn = () => {
    const dispatch = useDispatch();
    const goBack = () => {
        console.log('retour');
        dispatch(closeGame());
        const contentModal = {
            id: 1,
            text: 'Prêts à jouer ? Je reste en veille, si tu trouves une carte jaune machine avec un code n’hésite pas à le rentrer dans mon interface pour voir ce qu’il se passe.',
            type: 'smile',
        };
        dispatch(openCodeMachine(contentModal));
    };

    return (
        <div>
            <Button title="Retour" onClick={() => goBack()} />
        </div>
    );
};

export default ButtonReturn;

import React, { useState, useEffect } from 'react';
import TimeFormat from 'hh-mm-ss';

import './timer.css';

const Timer = ({ timerPause }) =>
{
    let mainTime;
    const [seconds, setSeconds] = useState(0);
    const display = TimeFormat.fromS(seconds, 'hh:mm:ss');
    const [h, m, s] = display.split(':');

    const stopTimer = () => {
        clearInterval(mainTime);
      };
    
      const tick = () => {
        setSeconds((second) => {
          const updatedSeconds = second + 1;
          if (updatedSeconds < 1) {
            stopTimer();
          }
          return updatedSeconds;
        });
      };
    
      const startTime = () => {
        if (seconds >= 0) {
          mainTime = setInterval(tick, 1000);
        }
      };
    
      useEffect(() => {
        if (!timerPause) {
          startTime();
        }
        return () => {
          stopTimer();
        };
      }, [timerPause]);
    
    return (
        <div className='timer'><p>{h !== '00' && h} {m} : {s}</p></div>
    )
}



export default Timer;
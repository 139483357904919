import React from "react";

import Button from '../../../button/Button';

import './tutorialRobot.css'

const TutorialRobot = ({ contentModal, slider}) =>
{
    const { type, id, text, onClick } = contentModal;
    const handleRobot = () =>
    {
        switch (type) {
            case 'smile':
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-content3.svg?alt=media&token=56bafe18-efcf-4d43-a8b2-87b6eaa1d240'
            case 'oh':
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-tete-content2.svg?alt=media&token=692f61c5-a516-4631-9b05-ff07e0705bd6'
            case 'bad':
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-tete-triste.svg?alt=media&token=2cfc38e0-c3ec-4d8c-845d-01ce3f0018d7'
            case 'win':
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-tete-win.svg?alt=media&token=6fd9afef-fff5-489b-a269-438d649bfceb'
            default:
                return 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fpersonnages%2Frobot-content3.svg?alt=media&token=56bafe18-efcf-4d43-a8b2-87b6eaa1d240'
        }
    }
    return (
        <div className="tutorialRobot">
            <img className="tutorialRobot_robot" src={handleRobot()} alt={`robot-${ id }`} />
            
                <img className="tutorialRobot_bubble" src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbubble%2Fbulle-principale.svg?alt=media&token=dae55950-7e72-49d4-ace0-bd7dba2f7a3c" alt="bulle robot" />
            <div className={`tutorialRobot_robot-content ${ type }`}>
                <p className="tutorialRobot_text">{text}</p>
                {(onClick || slider) &&
                (
                    <Button onClick={() => onClick ? onClick() : slider?.current?.slickNext()} title='Suivant' />
                )
                }
            </div>
            
            
        </div>
    )
}

export default TutorialRobot;